import styled from '@emotion/styled';

const Alert = styled.div`
    border: 1px solid black;
    border-radius: 8px;
    padding: 12px;
`;

export const ErrorAlert = styled(Alert)`
    border-color: #cc0000;
    background-color: #ffcccc;
    color: #000000;
`;

export const SuccessAlert = styled(Alert)`
    border-color: #00cc00;
    background-color: #ccffcc;
    color: #000000;
`;
