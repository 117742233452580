/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { add, format, sub } from 'date-fns';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Row } from '../../../components/Flex';
import { Arrow } from '../../../components/icons/Arrow';
import { Spinner } from '../../../components/Spinner';
import { useThemeStore } from '../../../themes/useThemeStore';

export const CalendarHeader: FC<HeaderType> = ({ date, rooms, changeRoom, currentRoom, isFetching }) => {
    const navigate = useNavigate();
    const { theme } = useThemeStore();
    const changeWeek = (nav: 'PREVIOUS' | 'NEXT' | 'TODAY') => {
        let newDate: Date;

        if (nav === 'NEXT') {
            newDate = add(date, { weeks: 1 });
        } else if (nav === 'PREVIOUS') {
            newDate = sub(date, { weeks: 1 });
        } else {
            newDate = new Date();
        }

        const navYear = format(newDate, 'yyyy');
        const navWeek = format(newDate, 'w');

        navigate(`/calendar/${navYear}/${navWeek}`);
    };

    return (
        <div
            css={css`
                position: fixed;
                top: 70px;
                right: 0;
                left: 0;
                height: 30px;
                display: flex;
                z-index: 100;
                align-items: center;
                padding: 0 24px;
                background-color: ${theme.colors.background.primary};
                width: 100vw;

                @media screen and (max-width: ${theme.screenSize.mobile}px) {
                    top: 100px;
                }
            `}
        >
            <select value={currentRoom} onChange={event => changeRoom(event.target.value)}>
                <option value="ALL">Alle rom</option>

                {rooms.map(room => (
                    <option key={room.id} value={room.name}>
                        {room.name}
                    </option>
                ))}
            </select>
            <Row
                vertical="center"
                className="week-navigation"
                css={css`
                    grid-area: week-navigation;
                `}
            >
                <div
                    css={css`
                        width: 20px;
                    `}
                >
                    {isFetching && <Spinner size={18} />}
                </div>

                <button onClick={() => changeWeek('PREVIOUS')}>
                    <Arrow size={14} />
                </button>
                <button onClick={() => changeWeek('TODAY')}>I dag</button>
                <button onClick={() => changeWeek('NEXT')}>
                    <Arrow size={14} direction="RIGHT" />
                </button>
            </Row>
        </div>
    );
};

type HeaderType = {
    date: Date;
    rooms: Array<{ id: string; name: string }>;
    changeRoom: (r: string) => void;
    currentRoom: string;
    refetchProfile: () => void;
    isFetching?: boolean;
};
