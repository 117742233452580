import { meetApi } from './api';
import { AxiosError } from 'axios';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { CalendarRes } from './useCalendar';

export const useGetAllRooms = () => {
    return useQuery<string[], AxiosError>(['all_rooms'], () =>
        meetApi.get<string[]>('api/v1/terminal/rooms').then(res => res.data)
    );
};

export const useCalendarRoom = (room: string) => {
    return useQuery<CalendarRes, AxiosError>(['calendar_room', room], () =>
        meetApi.get<CalendarRes>(`api/v1/terminal/rooms/${room}`).then(res => ({
            ...res.data,
            events: res.data.events.map(event => ({
                ...event,
                start: new Date(event.start),
                end: new Date(event.end),
            })),
        }))
    );
};

type BookRoom = {
    room: string;
    start: string;
    end: string;
};

export const useBookRoom = () => {
    const queryClient = useQueryClient();

    return useMutation<{ success: boolean }, AxiosError<{ success: boolean; message: string }>, BookRoom, unknown>(
        async (values: BookRoom) => {
            const res = await meetApi.post<{ success: boolean }>('/api/v1/terminal/event', values);

            queryClient.invalidateQueries('calendar_room');
            queryClient.invalidateQueries('all_rooms');

            return res.data;
        }
    );
};
