import create, { SetState } from 'zustand';

export type ToastType = {
    type?: 'error' | 'warning' | 'info' | 'success';
    message?: string;
    timeout?: number;
};

type ToastStoreType = {
    toast?: ToastType;
    setToast: (toast: ToastType) => void;
    clearToast: () => void;
};
export const useToastStore = create<ToastStoreType>((set: SetState<ToastStoreType>) => ({
    toast: undefined,
    setToast: (toast: ToastType) => set(() => ({ toast })),
    clearToast: () => set(() => ({ toast: undefined })),
}));
