/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { Column, Row } from '../../components/Flex';
import { useGetAllRooms } from '../../network/useTerminal';

export const SelectRoom: FC = () => {
    const { data, isLoading, error } = useGetAllRooms();
    const theme = useTheme();

    if (error) {
        return <div>{error.toString()}</div>;
    }

    if (isLoading) {
        return (
            <Row
                horizontal="center"
                vertical="center"
                css={css`
                    width: 100vw;
                    height: 100vh;
                `}
            >
                Loading...
            </Row>
        );
    }

    if (data) {
        return (
            <Column>
                {data.map(room => (
                    <Link
                        css={css`
                            width: 100%;
                            height: 50px;
                            border: 1px solid ${theme.colors.border.primary};
                        `}
                        key={room}
                        to={encodeURI(room)}
                    >
                        {room}
                    </Link>
                ))}
            </Column>
        );
    }

    return null;
};
