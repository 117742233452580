import { AxiosError } from 'axios';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { formatLocale } from '../utils/date';
import { meetApi } from './api';
import { EquipmentType } from './useDesk';
import { User } from './useProfile';

export const useGetAllUsers = () => {
    return useQuery<User[]>('all_users', async () => {
        const res = await meetApi.get('/api/v1/admin/users');

        return res.data;
    });
};

export const useChangeUser = () => {
    const queryClient = useQueryClient();

    return useMutation<
        { success: boolean },
        AxiosError<{ success: boolean; message: string }>,
        { userId: number; admin: boolean; company?: string },
        unknown
    >(async ({ userId, admin, company }: { userId: number; admin: boolean; company?: string }) => {
        const res = await meetApi.put<{ success: boolean }>(`/api/v1/admin/user/${userId}`, {
            admin,
            company,
        });

        if (res.data.success) {
            queryClient.invalidateQueries('all_users');
        }

        return res.data;
    });
};

type RoomType = {
    id: number;
    name: string;
    floor: number;
    disabled: boolean;
    description: string;
};

type DeskType = {
    id: number;
    created_at: string;
    updated_at: string;
    name: string;
    room_id: number;
    equipment: EquipmentType[];
    disabled: boolean;
};

export type AdminRoom = RoomType & {
    desks: DeskType[];
};

export const useEditRoom = () => {
    const queryClient = useQueryClient();

    return useMutation<{ success: boolean }, AxiosError<{ success: boolean; message: string }>, AdminRoom, unknown>(
        async (room: RoomType) => {
            const res = await meetApi.put<{ success: boolean }>(`/api/v1/admin/room/${room.id}`, room);

            if (res.data.success) {
                queryClient.invalidateQueries('all_rooms');
            }

            return res.data;
        }
    );
};

export const useGetAllRooms = () => {
    return useQuery<AdminRoom[]>('all_rooms', async () => {
        const res = await meetApi.get('/api/v1/admin/rooms');

        return res.data;
    });
};

type ReserveDesk = {
    deskId: string | number;
    userSub: string;
    startDate: Date;
    endDate: Date;
    note?: string;
};

export const useReserveAdminDesk = () => {
    const queryClient = useQueryClient();

    return useMutation<{ success: boolean }, AxiosError<{ success: boolean; message: string }>, ReserveDesk, unknown>(
        async (values: ReserveDesk) => {
            const res = await meetApi.post<{ success: boolean }>('/api/v1/admin/desks', {
                ...values,
                startDate: formatLocale(values.startDate, 'yyyy-MM-dd'),
                endDate: formatLocale(values.endDate, 'yyyy-MM-dd'),
            });

            queryClient.invalidateQueries('desk_bookings');

            return res.data;
        }
    );
};
