import { useState, useEffect } from 'react';
import { storage } from '../utils/storage';
import { ThemeTypes } from './useThemeStore';

export const PREFERRED_COLOR_SCHEME = 'PREFERRED_COLOR_SCHEME';

export const usePrefersColorScheme = (): {
    name: ThemeTypes;
    theme: 'LIGHT' | 'DARK';
    setTheme: (newTheme: ThemeTypes) => void;
} => {
    const [theme, setTheme] = useState(storage.getItemOr<ThemeTypes | 'SYSTEM'>(PREFERRED_COLOR_SCHEME, 'SYSTEM'));
    const [systemTheme, setSystemTheme] = useState<'LIGHT' | 'DARK'>(
        window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? 'DARK' : 'LIGHT'
    );

    useEffect(() => {
        const matchMedia =
            window && window.matchMedia
                ? window.matchMedia('(prefers-color-scheme: dark)')
                : ({
                      matches: true,
                      media: '(prefers-color-scheme: dark)',
                      onchange: null,
                  } as MediaQueryList);
        const listener = (event: MediaQueryListEvent) => {
            setSystemTheme(event.matches ? 'DARK' : 'LIGHT');
        };
        matchMedia.addEventListener('change', listener);

        return () => {
            matchMedia.removeEventListener('change', listener);
        };
    }, []);

    return {
        name: theme,
        theme: theme === 'SYSTEM' ? systemTheme : theme,
        setTheme: (newTheme: ThemeTypes | 'SYSTEM') => setTheme(newTheme),
    };
};
