/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import { addDays, isSameDay, isToday } from 'date-fns';
import { capitalize } from 'lodash';
import { FC, useEffect, useRef } from 'react';
import { Column } from '../../../components/Flex';
import { Area, Booking, Desk } from '../../../network/useDesk';
import { LunchBooking } from '../../../network/useLunch';
import { User } from '../../../network/useProfile';
import { formatLocale } from '../../../utils/date';
import { BookingButton } from './BookingButton';

export const DeskAreas: FC<{
    bookings: Booking[];
    lunchBookings: LunchBooking[];
    selectedWeek: Date;
    profile?: User;
    activeDeskArea: Area;
}> = ({ bookings, selectedWeek, profile, activeDeskArea, lunchBookings }) => {
    const theme = useTheme();
    const ref = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if (ref.current) {
            ref.current.scrollIntoView({ behavior: 'smooth' });
        }
    });

    return (
        <div
            css={css`
                max-width: calc(100vw - 12px);
                overflow-x: auto;
            `}
        >
            <div
                css={css`
                    display: grid;
                    grid-template-columns: repeat(5, minmax(120px, 150px));
                    gap: 24px;
                `}
            >
                {Array.from(Array(5).keys()).map(dayOffset => {
                    const date = addDays(selectedWeek, dayOffset);
                    const today = isToday(date);

                    return (
                        <Column key={dayOffset} ref={today ? ref : undefined}>
                            <div
                                css={css`
                                    display: flex;
                                    align-items: center;
                                    margin: 0 auto;
                                    white-space: nowrap;
                                    margin-bottom: 10px;
                                `}
                            >
                                {today ? 'I dag' : capitalize(formatLocale(date, 'EEE d'))}
                                {today && (
                                    <div
                                        css={css`
                                            margin-left: 5px;
                                            width: 10px;
                                            height: 10px;
                                            border-radius: 50%;
                                            background-color: ${theme.colors.button.active};
                                        `}
                                    />
                                )}
                            </div>
                            {activeDeskArea.desks.sort(deskSort).map(desk => {
                                const booking = bookings.find(
                                    booking =>
                                        isSameDay(new Date(booking.reservationDate), date) && booking.deskId === desk.id
                                );
                                return (
                                    <BookingButton
                                        key={desk.id}
                                        lunchBookings={lunchBookings}
                                        desk={desk}
                                        date={date}
                                        booking={booking}
                                        profile={profile}
                                        today={today}
                                    />
                                );
                            })}
                        </Column>
                    );
                })}
            </div>
        </div>
    );
};
export const groupBy = <T, K extends keyof any>(list: T[], getKey: (item: T) => K) =>
    list.reduce((previous, currentItem) => {
        const group = getKey(currentItem);
        if (!previous[group]) previous[group] = [];
        previous[group].push(currentItem);
        return previous;
    }, {} as Record<K, T[]>);

function deskSort(a: Desk, b: Desk) {
    const aNumber = parseInt(a.name.replace(/^.*#/g, ''));
    const bNumber = parseInt(b.name.replace(/^.*#/g, ''));
    if (isNaN(aNumber) || isNaN(bNumber)) return a.name.localeCompare(b.name);
    return aNumber - bNumber;
}
