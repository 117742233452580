/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import {
    addDays,
    eachDayOfInterval,
    endOfMonth,
    endOfWeek,
    format,
    getWeeksInMonth,
    isLastDayOfMonth,
    isSameDay,
    isSameMonth,
    isWeekend,
    parseISO,
    startOfMonth,
    startOfWeek,
} from 'date-fns';
import { FC, useEffect, useState } from 'react';
import { UseQueryResult } from 'react-query';
import { ErrorAlert, SuccessAlert } from '../../components/Alerts';
import { Header } from '../../components/Header';
import {
    LunchBooking,
    useBookLunch,
    useBookMultipleLunchDates,
    useDeleteBookedLunch,
    useGetMyLunchBookings,
    useWishLunchItem,
} from '../../network/useLunch';
import { formatLocale } from '../../utils/date';
import { useToastStore } from '../../utils/store/useToastStore';
import { Button } from '../desk/components/Button';

export const Lunch = () => {
    const date = new Date();
    const theme = useTheme();
    const { setToast } = useToastStore();
    const myLunchBookingsQuery = useGetMyLunchBookings(startOfMonth(date), endOfMonth(date));
    const deleteBookedLunch = useDeleteBookedLunch();
    const lunchBooking = useBookLunch();
    const multipleLunchBooking = useBookMultipleLunchDates();
    const currentLunchBooking = myLunchBookingsQuery.data?.find(booking => isSameDay(new Date(booking.date), date));
    const isLocalhost = window.location.hostname === 'localhost';

    const renderTotalAmount = (allBookings: any) => {
        let amount = 0;

        for (let booking of allBookings) {
            let date = parseISO(booking.date);
            let thisMonth = date.getMonth();

            if (date.getMonth() === thisMonth) {
                amount += parseInt(booking.amount);
            }
        }

        if (amount > 40000) {
            return 400;
        }

        return amount / 100;
    };

    useEffect(() => {
        if (lunchBooking.isError) {
            setToast({
                type: 'error',
                message: lunchBooking.error.response?.data?.message || 'Noe gikk galt...',
            });
        }
    }, [lunchBooking.isError, lunchBooking.error, setToast]);

    return (
        <div
            css={css`
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin-top: 15vh;
                margin-bottom: 20px;

                @media screen and (max-width: ${theme.screenSize.mobile}px) {
                    margin-top: 30vh;
                }
            `}
        >
            <Header date={date} />
            <Button
                onClick={async () => {
                    if (currentLunchBooking) {
                        await deleteBookedLunch.mutateAsync({
                            id: currentLunchBooking.id,
                        });
                    } else {
                        await lunchBooking.mutateAsync({ date: date });
                    }

                    myLunchBookingsQuery.refetch();
                }}
                css={css`
                    background-color: ${Boolean(currentLunchBooking)
                        ? theme.colors.button.danger
                        : theme.colors.button.clickable};
                    color: ${theme.colors.text.secondary};
                    margin-bottom: 100px;
                    &:hover {
                        background-color: ${Boolean(currentLunchBooking)
                            ? theme.colors.button.danger
                            : theme.colors.button.clickable};
                    }
                `}
            >
                <h2>{Boolean(currentLunchBooking) ? 'Avbook i dag' : 'Book i dag'}</h2>
            </Button>

            <div
                css={css`
                    display: flex;
                    margin-bottom: 32px;
                `}
            >
                <Button
                    disabled={date.getDay() === 5}
                    onClick={async () => {
                        await multipleLunchBooking.mutateAsync({
                            dates: generateDates(date, addDays(date, 5 - date.getDay())),
                        });
                        myLunchBookingsQuery.refetch();
                    }}
                >
                    Book resten av uken
                </Button>
                <div
                    css={css`
                        width: 16px;
                    `}
                />
                <Button
                    disabled={isLastDayOfMonth(date)}
                    onClick={async () => {
                        await multipleLunchBooking.mutateAsync({
                            dates: generateDates(date, endOfMonth(date)),
                        });
                        myLunchBookingsQuery.refetch();
                    }}
                >
                    Book resten av måneden
                </Button>
            </div>

            <h3> I {formatLocale(date, 'MMMM')} har du bestilt lunsj: </h3>
            {myLunchBookingsQuery.isLoading && <div>Laster...</div>}
            {myLunchBookingsQuery.data && (
                <div>
                    <div
                        css={css`
                            border: 1px solid ${theme.colors.border.primary};
                            display: grid;
                            grid-template-columns: repeat(5, 50px);
                            grid-template-rows: repeat(${getWeeksInMonth(date) + 1}, auto);
                            grid-gap: 16px;
                            padding-bottom: 16px;
                            border-radius: 8px;
                        `}
                    >
                        {['M', 'T', 'O', 'T', 'F'].map((d, i) => (
                            <div
                                key={i}
                                css={css`
                                    margin-top: 12px;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                `}
                            >
                                {d}
                            </div>
                        ))}

                        {generateCalendarDates().map(d => (
                            <Day
                                key={d.toDateString()}
                                date={d}
                                currentDate={date}
                                myLunchBookingsQuery={myLunchBookingsQuery}
                                lunchBooking={myLunchBookingsQuery.data.find(data => isSameDay(new Date(data.date), d))}
                            />
                        ))}
                    </div>
                    <div
                        css={css`
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            margin-top: 8px;
                            font-size: 12px;
                        `}
                    >
                        <div>
                            Trykk for på datoene å booke/avbooke.
                            <br />
                            🥪&nbsp;&nbsp;= booket 😵&nbsp;&nbsp;= ikke booket
                        </div>
                    </div>
                    <h3>
                        Totalt å betale i {formatLocale(date, 'MMMM')}: {renderTotalAmount(myLunchBookingsQuery.data)}{' '}
                        kr
                    </h3>
                </div>
            )}
            {isLocalhost && <LunchWish />}
        </div>
    );
};

const Day: FC<{
    date: Date;
    currentDate: Date;
    lunchBooking?: LunchBooking;
    myLunchBookingsQuery: UseQueryResult<LunchBooking[], unknown>;
}> = ({ myLunchBookingsQuery, date, currentDate, lunchBooking }) => {
    const deleteBookedLunch = useDeleteBookedLunch();
    const bookedLunch = Boolean(lunchBooking);
    const multipleLunchBooking = useBookMultipleLunchDates();
    const sameMonth = isSameMonth(currentDate, date);
    const sameDay = isSameDay(currentDate, date);

    return (
        <button
            css={css`
                opacity: ${sameMonth ? '1' : '0.4'};
                cursor: ${sameMonth ? 'pointer' : 'not-allowed'};
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                font-size: 24px;
            `}
            onClick={async () => {
                if (sameMonth) {
                    if (lunchBooking) {
                        await deleteBookedLunch.mutateAsync({
                            id: lunchBooking.id,
                        });
                    } else {
                        await multipleLunchBooking.mutateAsync({
                            dates: [date],
                        });
                    }
                }
                myLunchBookingsQuery.refetch();
            }}
        >
            <div
                css={css`
                    position: relative;
                `}
            >
                {sameDay && (
                    <span
                        css={css`
                            position: absolute;
                            top: -5px;
                            right: -5px;
                            width: 10px;
                            height: 10px;
                            background-color: red;
                            border-radius: 100px;
                        `}
                    />
                )}
                {format(date, 'dd')}
            </div>
            {sameMonth ? (bookedLunch ? '🥪' : '😵') : ' '}
        </button>
    );
};

function generateDates(start: Date, end: Date) {
    let dates: Date[] = [];
    let currentDate = start;

    while (currentDate <= end) {
        dates.push(currentDate);
        currentDate = addDays(currentDate, 1);
    }

    const filterOutWeekends = (date: Date) => {
        return date.getDay() !== 0 && date.getDay() !== 6;
    };

    return dates.filter(filterOutWeekends);
}

function getStartOfWeek(date: Date) {
    return startOfWeek(date, { weekStartsOn: 1 });
}

function generateCalendarDates(date: Date = new Date()) {
    return eachDayOfInterval({
        start: getStartOfWeek(startOfMonth(date)),
        end: endOfWeek(endOfMonth(date)),
    }).filter(d => !isWeekend(d));
}

const LunchWish: FC = () => {
    const theme = useTheme();
    const lunchWish = useWishLunchItem();
    const [url, setUrl] = useState('');
    const [error, setError] = useState('');

    const handleWishClick = async () => {
        await lunchWish.mutateAsync({ url: url });
    };

    return (
        <div
            css={css`
                margin-top: 32px;
                margin-bottom: 32px;
            `}
        >
            <div>
                <input
                    type="text"
                    value={url}
                    onChange={e => setUrl(e.target.value)}
                    disabled={lunchWish.isLoading}
                    css={css`
                        border: 1px solid ${theme.colors.border.primary};
                        padding: 8px;
                        border-radius: 4px;
                    `}
                    placeholder="URL til Oda.com"
                    onBlur={() => {
                        if (url && !url.includes('oda.com')) {
                            setError('URL må være fra Oda.com');
                        } else {
                            setError('');
                        }
                    }}
                />
                <Button
                    css={css`
                        margin-left: 8px;
                    `}
                    onClick={handleWishClick}
                    disabled={lunchWish.isLoading}
                >
                    {lunchWish.isLoading ? 'Lagrer...' : 'Lagre'}
                </Button>
            </div>
            {(error || lunchWish.error) && (
                <ErrorAlert
                    css={css`
                        margin-top: 24px;
                    `}
                >
                    {error && error}
                    {lunchWish.error && lunchWish.error.message}
                </ErrorAlert>
            )}
            {lunchWish.data?.success && (
                <SuccessAlert
                    css={css`
                        margin-top: 24px;
                    `}
                >
                    Ditt ønske er registrert
                </SuccessAlert>
            )}
        </div>
    );
};
