import styled from '@emotion/styled';

export const Button = styled.button<{ active?: boolean }>`
    padding: 8px 16px;
    color: ${props => (props.active ? props.theme.colors.text.active : props.theme.colors.text.primary)};
    background-color: ${props => (props.active ? props.theme.colors.button.active : props.theme.colors.button.primary)};
    border-radius: 5px;

    &:disabled {
        opacity: 0.4;
        cursor: no-drop;
    }
`;
