import { ResizeObserver } from '@juggle/resize-observer';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { App } from './App';
import * as serviceWorker from './serviceWorker';
(window as any).ResizeObserver = ResizeObserver;

const { REACT_APP_SENTRY_DSN } = process.env;
const environment = window.location.hostname === 'meet-the-web.test.iterate.no' ? 'test' : 'production';

if (REACT_APP_SENTRY_DSN) {
    Sentry.init({
        dsn: REACT_APP_SENTRY_DSN,
        integrations: [new Integrations.BrowserTracing()],
        tracesSampleRate: 1.0,
        environment,
    });
}

ReactDOM.render(
    <BrowserRouter>
        <App />
    </BrowserRouter>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
