/** @jsxImportSource @emotion/react */
import { useConfirmTodaysDeskBooking, ConfirmBookingStatus } from '../../network/useDesk';
import { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

type Status = 'loading' | ConfirmBookingStatus;

const MessageComponent = styled.p`
    font-size: 20px;
    text-align: center;
    margin: 10px 0 0 0;
`;

const EmojiComponent = styled.p`
    font-size: 60px;
    margin: 0;
`;

const LinkComponent = styled.a`
    color: #0000ee;
    text-decoration: underline;
    cursor: pointer;
    margin: 16px 0 0 0;
`;

export const ConfirmDeskBooking = () => {
    const confirmTodaysBooking = useConfirmTodaysDeskBooking();
    const [status, setStatus] = useState<Status>('loading');
    const [bookedDesks, setBookedDesks] = useState<string[]>([]);

    useEffect(() => {
        confirmTodaysBooking
            .mutateAsync()
            .then(response => {
                switch (response.status) {
                    case 'no_bookings_found':
                        setStatus('no_bookings_found');
                        break;
                    case 'confirmed':
                        setStatus('confirmed');
                        setBookedDesks(response.bookedDesks ?? []);
                        break;
                    default:
                        setStatus('error');
                }
            })
            .catch(e => {
                console.error(e);
                setStatus('error');
            });
    }, []);

    return (
        <div
            css={css`
                display: flex;
                height: 100vh;
                width: 100vw;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                padding: 30px;
            `}
        >
            {status === 'loading' && (
                <>
                    <EmojiComponent>⚙️</EmojiComponent>
                    <MessageComponent>Laster...</MessageComponent>
                </>
            )}
            {status === 'error' && (
                <>
                    <EmojiComponent>🙅‍♀️</EmojiComponent>
                    <MessageComponent>Oops, det skjedde en feil...</MessageComponent>
                </>
            )}
            {status === 'no_bookings_found' && (
                <>
                    <EmojiComponent>🥸</EmojiComponent>
                    <MessageComponent>Du har ingen pultbookinger registrert i dag</MessageComponent>
                    <LinkComponent href="/desk">Til pultbooking</LinkComponent>
                </>
            )}
            {status === 'confirmed' && (
                <>
                    <EmojiComponent>🤑</EmojiComponent>
                    <MessageComponent>Pultbooking bekreftet!</MessageComponent>
                    <MessageComponent>{formatDeskText(bookedDesks)}</MessageComponent>
                    <LinkComponent href="/desk/delete-booking">Avregistrere booking? Klikk her</LinkComponent>
                </>
            )}
        </div>
    );
};

function formatDeskText(desks: string[]) {
    if (desks.length === 0) {
        return '';
    }

    let text = 'Pult';
    if (desks.length > 1) {
        text += 'er';
    }
    text += ': ';
    return text + desks.join(', ');
}
