/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import { addMonths, endOfMonth, startOfMonth, subMonths } from 'date-fns';
import { upperFirst } from 'lodash';
import { lighten } from 'polished';
import { FC, useState } from 'react';
import { Column, Row } from '../../components/Flex';
import { Header } from '../../components/Header';
import { AdminLunchBooking, useGetAllLunchBookings } from '../../network/useLunch';
import { formatLocale } from '../../utils/date';
import { Button } from '../desk/components/Button';

type AggregatedLunchBookings = {
    name: string;
    bookings: string[];
    company: string;
    amount: number;
    sub: string;
};

export const LunchBookings: FC = () => {
    const [date, setDate] = useState(new Date());
    const [sortBy, setSortBy] = useState('name-asc');
    const { data, isLoading } = useGetAllLunchBookings(startOfMonth(date), endOfMonth(date));
    const theme = useTheme();

    const aggregatedLunchData =
        data?.reduce((acc: AggregatedLunchBookings[], cur: AdminLunchBooking) => {
            if (acc.length === 0) {
                acc.push({
                    name: cur.name,
                    bookings: [cur.date],
                    company: cur.company,
                    amount: cur.amount,
                    sub: cur.sub,
                });
            } else {
                const accIndex = acc.findIndex(a => a.sub === cur.sub);
                if (accIndex === -1) {
                    acc.push({
                        name: cur.name,
                        bookings: [cur.date],
                        company: cur.company,
                        amount: cur.amount,
                        sub: cur.sub,
                    });
                } else {
                    acc[accIndex].bookings.push(cur.date);
                    acc[accIndex].amount += cur.amount;

                    if (acc[accIndex].amount >= 40000) {
                        acc[accIndex].amount = 40000;
                    }
                }
            }

            return acc;
        }, []) ?? [];

    return (
        <div>
            <Header date={new Date()} />
            <Column
                css={css`
                    width: 100%;
                    padding: 0px 24px;
                    @media screen and (max-width: 800px) {
                        padding: 0px 6px;
                        margin-top: 100px;
                    }
                    padding-top: 100px;
                `}
            >
                <h1>Lunch bookings</h1>
                <Row align="center">
                    <Button
                        onClick={() => setDate(subMonths(date, 1))}
                        css={css`
                            width: 120px;
                            font-size: 15px;
                        `}
                    >
                        {upperFirst(formatLocale(subMonths(date, 1), 'MMMM'))}
                    </Button>
                    <div
                        css={css`
                            width: 140px;
                            text-align: center;
                            font-weight: bold;
                            font-size: 20px;
                        `}
                    >
                        {upperFirst(formatLocale(date, 'MMMM'))}
                    </div>
                    <Button
                        onClick={() => setDate(addMonths(date, 1))}
                        disabled={addMonths(date, 1) > new Date()}
                        css={css`
                            width: 120px;
                            font-size: 15px;
                        `}
                    >
                        {upperFirst(formatLocale(addMonths(date, 1), 'MMMM'))}
                    </Button>
                </Row>
                {isLoading && <div>Loading...</div>}
                {data && (
                    <>
                        <Column
                            css={css`
                                margin-top: 32px;
                                font-size: 20px;
                            `}
                        >
                            <div>
                                {aggregatedLunchData
                                    .reduce(
                                        (
                                            acc: {
                                                company: string;
                                                amount: number;
                                            }[],
                                            cur
                                        ) => {
                                            if (acc.length === 0) {
                                                acc.push({
                                                    company: cur.company,
                                                    amount: cur.amount,
                                                });
                                            } else {
                                                const accIndex = acc.findIndex(a => a.company === cur.company);
                                                if (accIndex === -1) {
                                                    acc.push({
                                                        company: cur.company,
                                                        amount: cur.amount,
                                                    });
                                                } else {
                                                    acc[accIndex].amount += cur.amount;
                                                }
                                            }

                                            return acc;
                                        },
                                        []
                                    )
                                    .map(company => (
                                        <div key={company.company}>
                                            {company.company}: {company.amount / 100} kr
                                        </div>
                                    ))}
                            </div>
                            <div
                                css={css`
                                    margin-top: 16px;
                                `}
                            >
                                Sum {formatLocale(date, 'MMMM')}:{' '}
                                {aggregatedLunchData.reduce((acc, cur) => acc + cur.amount, 0) / 100} kr
                            </div>
                        </Column>
                        <table
                            css={css`
                                margin-top: 32px;
                                margin-bottom: 64px;
                                border-collapse: collapse;

                                th,
                                td {
                                    padding-left: 4px;
                                    text-align: left;
                                }

                                tbody {
                                    tr {
                                        &:hover {
                                            td {
                                                background-color: ${theme.colors.background.secondary};
                                            }
                                        }
                                        td {
                                            padding-top: 8px;
                                            padding-bottom: 8px;
                                        }
                                    }
                                }
                            `}
                        >
                            <thead>
                                <tr>
                                    <th onClick={() => setSortBy(cs => (cs === 'name-asc' ? 'name-desc' : 'name-asc'))}>
                                        {`Navn ${
                                            (sortBy === 'name-asc' && '▲') || (sortBy === 'name-desc' && '▼') || ''
                                        }`}
                                    </th>
                                    <th
                                        onClick={() =>
                                            setSortBy(cs => (cs === 'company-asc' ? 'company-desc' : 'company-asc'))
                                        }
                                    >
                                        {`Selskap ${
                                            (sortBy === 'company-asc' && '▲') ||
                                            (sortBy === 'company-desc' && '▼') ||
                                            ''
                                        }`}
                                    </th>
                                    <th
                                        onClick={() =>
                                            setSortBy(cs => (cs === 'bookings-asc' ? 'bookings-desc' : 'bookings-asc'))
                                        }
                                    >
                                        {`Antall ${
                                            (sortBy === 'bookings-asc' && '▲') ||
                                            (sortBy === 'bookings-desc' && '▼') ||
                                            ''
                                        }`}
                                    </th>
                                    <th
                                        onClick={() =>
                                            setSortBy(cs => (cs === 'amount-asc' ? 'amount-desc' : 'amount-asc'))
                                        }
                                        css={css`
                                            text-align: right !important;
                                            padding-right: 4px;
                                        `}
                                    >
                                        {`Beløp ${
                                            (sortBy === 'amount-asc' && '▲') || (sortBy === 'amount-desc' && '▼') || ''
                                        }`}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {aggregatedLunchData
                                    .sort((a: AggregatedLunchBookings, b: AggregatedLunchBookings) => {
                                        if (sortBy === 'name-asc') {
                                            return a.name.localeCompare(b.name);
                                        } else if (sortBy === 'name-desc') {
                                            return b.name.localeCompare(a.name);
                                        } else if (sortBy === 'company-asc') {
                                            return a.company.localeCompare(b.company);
                                        } else if (sortBy === 'company-desc') {
                                            return b.company.localeCompare(a.company);
                                        } else if (sortBy === 'bookings-asc') {
                                            return a.bookings.length - b.bookings.length;
                                        } else if (sortBy === 'bookings-desc') {
                                            return b.bookings.length - a.bookings.length;
                                        } else if (sortBy === 'amount-asc') {
                                            return a.amount - b.amount;
                                        } else if (sortBy === 'amount-desc') {
                                            return b.amount - a.amount;
                                        }
                                        return 0;
                                    })
                                    .map(lunch => (
                                        <LunchRow key={lunch.sub} lunch={lunch} />
                                    ))}
                                <tr
                                    css={css`
                                        font-weight: bold;
                                    `}
                                >
                                    <td colSpan={3}>Totalt</td>
                                    <td>{aggregatedLunchData.reduce((acc, cur) => acc + cur.amount, 0) / 100} kr</td>
                                </tr>
                            </tbody>
                        </table>
                    </>
                )}
            </Column>
        </div>
    );
};

const LunchRow: FC<{ lunch: AggregatedLunchBookings }> = ({ lunch }) => {
    const [expanded, setExpanded] = useState(false);
    const theme = useTheme();

    return (
        <>
            <tr
                key={lunch.sub}
                onClick={() => setExpanded(cs => !cs)}
                css={
                    expanded &&
                    css`
                        background-color: ${lighten(0.06, theme.colors.background.secondary)};
                    `
                }
            >
                <td>{lunch.name}</td>
                <td>{lunch.company}</td>
                <td>{lunch.bookings.length}</td>
                <td
                    css={css`
                        text-align: right !important;
                        padding-right: 4px;
                    `}
                >
                    {lunch.amount / 100} kr
                </td>
            </tr>
            {expanded && (
                <tr
                    css={css`
                        background-color: ${lighten(0.06, theme.colors.background.secondary)};
                    `}
                >
                    <td colSpan={4}>
                        <table
                            css={css`
                                border-collapse: collapse;

                                width: 100%;
                                margin-top: 8px;
                                margin-bottom: 8px;
                                th,
                                td {
                                    padding-left: 4px;
                                    text-align: left;
                                }
                            `}
                        >
                            <thead>
                                <tr>
                                    <th
                                        css={css`
                                            width: 40px;
                                        `}
                                    />
                                    <th>Dato</th>
                                    <th
                                        css={css`
                                            text-align: right !important;
                                            padding-right: 4px;
                                        `}
                                    >
                                        Beløp
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {lunch.bookings.map((booking, index) => {
                                    const amount = 25;
                                    const accumulatedAmount = amount * (index + 1);

                                    return (
                                        <tr key={booking}>
                                            <td
                                                css={css`
                                                    width: 40px;
                                                `}
                                            />
                                            <td>{formatLocale(new Date(booking), 'dd.MM.yyyy')}</td>
                                            <td
                                                css={css`
                                                    text-align: right !important;
                                                    padding-right: 4px;
                                                `}
                                            >
                                                {accumulatedAmount > 400 ? 0 : amount} kr
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </td>
                </tr>
            )}
        </>
    );
};
