/** @jsxImportSource @emotion/react */
import { useRemoveTodaysDeskBooking, RemoveBookingStatus } from '../../network/useDesk';
import { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

type Status = RemoveBookingStatus | 'loading';

const MessageComponent = styled.p`
    font-size: 20px;
    text-align: center;
    margin: 10px 0 0 0;
`;

const EmojiComponent = styled.p`
    font-size: 60px;
    margin: 0;
`;

const LinkComponent = styled.a`
    color: #0000ee;
    text-decoration: underline;
    cursor: pointer;
    margin: 16px 0 0 0;
`;

export const RemoveDeskBooking = () => {
    const removeTodaysBooking = useRemoveTodaysDeskBooking();
    const [status, setStatus] = useState<Status>('loading');

    useEffect(() => {
        removeTodaysBooking
            .mutateAsync()
            .then(response => {
                switch (response.status) {
                    case 'ok':
                        setStatus('ok');
                        break;
                    default:
                        setStatus('error');
                }
            })
            .catch(e => {
                console.error(e);
                setStatus('error');
            });
    }, []);

    return (
        <div
            css={css`
                display: flex;
                height: 100vh;
                width: 100vw;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                padding: 30px;
            `}
        >
            {status === 'loading' && (
                <>
                    <EmojiComponent>⚙️</EmojiComponent>
                    <MessageComponent>Laster...</MessageComponent>
                </>
            )}
            {status === 'error' && (
                <>
                    <EmojiComponent>🙅‍♀️</EmojiComponent>
                    <MessageComponent>Oops, det skjedde en feil...</MessageComponent>
                </>
            )}
            {status === 'ok' && (
                <>
                    <EmojiComponent>🥸</EmojiComponent>
                    <MessageComponent>Dine pultbookinger i dag er fjernet!</MessageComponent>
                    <LinkComponent href="/desk">Til pultbooking</LinkComponent>
                </>
            )}
        </div>
    );
};
