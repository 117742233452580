/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import { FC, useEffect, useState } from 'react';
import { Area } from '../../../network/useDesk';
import { groupBy } from './DeskAreas';

export const AreaNames: FC<{
    areas: Area[];
    activeDeskArea: Area;
    setActiveDeskArea: (areaId: number) => void;
}> = ({ areas, activeDeskArea, setActiveDeskArea }) => {
    const theme = useTheme();
    const [activeFloor, setActiveFloor] = useState<string>('3');
    const [groupedAreasByFloor, setGroupedAreasByFloor] = useState<Record<number, Area[]>>();

    useEffect(() => {
        setGroupedAreasByFloor(groupBy(areas, a => a.floor));
    }, [areas]);

    return (
        <div
            css={css`
                @media screen and (max-width: 800px) {
                    max-width: 100%;
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                }
            `}
        >
            {groupedAreasByFloor &&
                Object.entries(groupedAreasByFloor)
                    .reverse()
                    .map(([index, area]) => (
                        <div key={index}>
                            <p
                                css={css`
                                    color: ${index === activeFloor
                                        ? theme.colors.text.primary
                                        : theme.colors.text.muted};
                                    font-weight: bold;
                                    font-size: 24px;
                                    margin-top: 0px;
                                `}
                            >
                                {index}. etg
                            </p>
                            {area.map(({ name, description, id }) => (
                                <div
                                    key={name}
                                    css={css`
                                        margin: 16px 0;
                                    `}
                                >
                                    <p
                                        css={css`
                                            color: ${activeDeskArea.name === name
                                                ? theme.colors.button.active
                                                : theme.colors.text.muted};
                                            font-weight: bold;
                                            margin: 0px;
                                        `}
                                    >
                                        <button
                                            onClick={() => {
                                                setActiveFloor(index);
                                                setActiveDeskArea(id);
                                            }}
                                        >
                                            {name}
                                        </button>
                                    </p>
                                    {description && (
                                        <span
                                            css={css`
                                                font-size: 14px;
                                            `}
                                        >
                                            {description}
                                        </span>
                                    )}
                                </div>
                            ))}
                        </div>
                    ))}
        </div>
    );
};
