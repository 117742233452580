import { AxiosError } from 'axios';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { meetApi } from './api';

export type User = {
    admin: boolean;
    company: null | string;
    created_at: string;
    email: string;
    hd: string;
    id: number;
    name: string;
    picture: undefined | string;
    sub: string;
    updated_at: string;
};

export const useProfile = () => {
    return useQuery<User, AxiosError>(
        'profile',
        async () => {
            const res = await meetApi(`api/v1/profile${window.location.search}`);
            return res.data;
        },
        {
            refetchOnWindowFocus: false,
            retry: false,
        }
    );
};

export const useAddCompanyName = () => {
    const queryClient = useQueryClient();
    return useMutation(
        async (company: string) => {
            const res = await meetApi.post('api/v1/profile', { company });

            return res.data;
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries('profile');
            },
        }
    );
};

export const useLogOut = () => {
    const queryClient = useQueryClient();

    return useMutation<{ success: boolean }, AxiosError>(async () => {
        const res = await meetApi.post<{ success: boolean }>('/api/v1/logout');
        queryClient.invalidateQueries('profile');

        return res.data;
    });
};
