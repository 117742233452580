/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import { FC } from 'react';
import { Modal } from '../../../components/Modal';
import { Desk as DeskType } from '../../../network/useDesk';
import { LunchBooking, useBookLunch, useDeleteBookedLunch } from '../../../network/useLunch';
import { formatLocale } from '../../../utils/date';
import { Button } from './Button';

export const BookingModal: FC<{
    desk: DeskType;
    date: Date;
    note: string;
    bookingLoading: boolean;
    currentLunchBooking?: LunchBooking;
    bookedLunch: boolean;
    closeModal: () => void;
    updateNote: (note: string) => void;
    handleUpdateDeskReservation: () => void;
    setBookedLunch: () => void;
}> = ({
    closeModal,
    desk,
    date,
    note,
    updateNote,
    bookingLoading,
    handleUpdateDeskReservation,
    currentLunchBooking,
    setBookedLunch,
    bookedLunch,
}) => {
    const theme = useTheme();
    const deleteBookedLunch = useDeleteBookedLunch();
    const lunchBooking = useBookLunch();

    return (
        <Modal closeModal={closeModal}>
            <div>
                <b>
                    Du har booket pult {desk.name} {formatLocale(date, 'EEEE dd. MMMM')}!
                </b>
            </div>

            <span
                css={css`
                    color: ${theme.colors.text.secondary};
                    margin-top: 10px;
                `}
            >
                Notat
            </span>

            <div
                css={css`
                    width: 100%;
                    display: flex;
                    align-items: center;
                    height: 40px;
                    margin-top: 8px;
                `}
            >
                <input
                    type="text"
                    placeholder="Eks: Fra 12:00"
                    value={note}
                    onChange={e => updateNote(e.target.value)}
                    title="Legg til notat"
                    css={css`
                        width: 100%;
                        height: 100%;
                        padding: 8px;
                        background-color: transparent;
                        border-radius: 3px;
                        border: 1px solid ${theme.colors.border.primary};

                        &:focus {
                            outline: none;
                            border-color: ${theme.colors.border.primary};
                        }
                    `}
                />
                <Button
                    css={css`
                        background-color: ${theme.colors.button.clickable};
                        color: ${theme.colors.text.secondary};
                        margin-left: 8px;
                        height: 100%;
                        &:hover {
                            background-color: ${bookedLunch
                                ? theme.colors.button.danger
                                : theme.colors.button.clickable};
                        }
                    `}
                    disabled={!note || bookingLoading}
                    title="Lagre notat"
                    onClick={() => note && handleUpdateDeskReservation()}
                >
                    Lagre notat
                </Button>
            </div>
            <div style={{ height: 24 }} />
            {/* <Button
                onClick={() => {
                    if (currentLunchBooking) {
                        deleteBookedLunch.mutate({
                            id: currentLunchBooking.id,
                        });
                    } else {
                        lunchBooking.mutate({ date });
                    }

                    setBookedLunch();
                }}
                css={css`
                    background-color: ${bookedLunch ? theme.colors.button.danger : theme.colors.button.clickable};
                    color: ${theme.colors.text.secondary};
                    margin-top: 10px;
                    &:hover {
                        background-color: ${bookedLunch ? theme.colors.button.danger : theme.colors.button.clickable};
                    }
                `}
            >
                <b>{bookedLunch ? 'Avbook lunsj' : 'Book lunsj'}</b>
            </Button> */}
            <Button
                onClick={closeModal}
                css={css`
                    background-color: ${bookedLunch ? theme.colors.button.danger : theme.colors.button.clickable};
                    color: ${theme.colors.text.secondary};
                    margin-top: 10px;
                    &:hover {
                        background-color: ${bookedLunch ? theme.colors.button.danger : theme.colors.button.clickable};
                    }
                `}
            >
                <b>Lukk</b>
            </Button>
        </Modal>
    );
};
