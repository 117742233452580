import { format } from 'date-fns';
import { nb } from 'date-fns/locale';

export function formatLocale(
    date: Date,
    formatString: string,
    options?: {
        locale?: Locale;
        weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
        firstWeekContainsDate?: number;
        useAdditionalWeekYearTokens?: boolean;
        useAdditionalDayOfYearTokens?: boolean;
    }
): string {
    return format(date, formatString, {
        weekStartsOn: 1,
        locale: nb,
        ...options,
    });
}
