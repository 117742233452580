/** @jsxImportSource @emotion/react */
import { css, keyframes, ThemeProvider } from '@emotion/react';
import { format } from 'date-fns';
import { FC, useEffect } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { Row } from './components/Flex';
import { PageNotFound } from './components/PageNotFound';
import { GlobalStyle } from './Global';
import { useProfile, User } from './network/useProfile';
import { Calendar } from './pages/calendar/Calendar';
import { AllDeskBookings } from './pages/admin/AllDeskBookings';
import { Desk } from './pages/desk/Desk';
import { QuickDeskBooking } from './pages/desk/QuickDeskBooking';
import { LunchBookings } from './pages/admin/LunchBookings';
import { Lunch } from './pages/lunch/Lunch';
import { AddCompanyNameModal } from './pages/profile/AddCompanyNameModal';
import { Profile } from './pages/profile/Profile';
import { SignIn } from './pages/signin/SignIn';
import { SelectRoom } from './pages/terminal/SelectRoom';
import { Terminal } from './pages/terminal/Terminal';
import { MeetThemeProvider, useThemeStore } from './themes/useThemeStore';
import { useToastStore } from './utils/store/useToastStore';
import { Users } from './pages/admin/Users';
import { Rooms } from './pages/admin/Rooms';
import { ConfirmDeskBooking } from './pages/desk/ConfirmDeskBooking';
import { RemoveDeskBooking } from './pages/desk/RemoveDeskBooking';

const queryClient = new QueryClient();

export const App = () => {
    return (
        <QueryClientProvider client={queryClient}>
            <MeetThemeProvider>
                <InnerApp />
            </MeetThemeProvider>
        </QueryClientProvider>
    );
};

const InnerApp: FC = () => {
    const { theme } = useThemeStore();
    const profile = useProfile();

    return (
        <ThemeProvider theme={theme}>
            <GlobalStyle />
            {profile.data && <AddCompanyNameModal profile={profile.data} />}
            <Routes>
                <Route
                    path="/"
                    element={
                        <RequireAuth>
                            <RootRedirect profile={profile.data} />
                        </RequireAuth>
                    }
                />
                <Route
                    element={
                        <RequireAuth>
                            <CalendarRedirect />
                        </RequireAuth>
                    }
                    path="/calendar"
                />
                <Route
                    element={
                        <RequireAuth>
                            <Calendar refetchProfile={() => profile.refetch()} profile={profile.data} />
                        </RequireAuth>
                    }
                    path="/calendar/:year/:week"
                />
                <Route
                    element={
                        <RequireAuth>
                            <ConfirmDeskBooking />
                        </RequireAuth>
                    }
                    path="/desk/confirm-booking"
                />
                <Route
                    element={
                        <RequireAuth>
                            <RemoveDeskBooking />
                        </RequireAuth>
                    }
                    path="/desk/delete-booking"
                />
                <Route
                    element={
                        <RequireAuth>
                            <Desk profile={profile.data} />
                        </RequireAuth>
                    }
                    path="/desk"
                />

                <Route
                    element={
                        <RequireAuth>
                            <Profile />
                        </RequireAuth>
                    }
                    path="/profile"
                />
                <Route
                    element={
                        <RequireAuth>
                            <Lunch />
                        </RequireAuth>
                    }
                    path="/lunch"
                />
                <Route
                    element={
                        <RequireAuth admin={true}>
                            <AllDeskBookings />
                        </RequireAuth>
                    }
                    path="/admin/desk"
                />
                <Route
                    element={
                        <RequireAuth admin={true}>
                            <Rooms />
                        </RequireAuth>
                    }
                    path="/admin/rooms"
                />
                <Route
                    element={
                        <RequireAuth>
                            <LunchBookings />
                        </RequireAuth>
                    }
                    path="/admin/lunch/bookings"
                />
                <Route
                    element={
                        <RequireAuth>
                            <Users />
                        </RequireAuth>
                    }
                    path="/admin/users"
                />
                <Route element={<QuickDeskBooking />} path="/desk/quick-book/:deskId" />
                <Route element={<SignIn />} path="/sign-in" />
                <Route element={<SelectRoom />} path="/terminal" />
                <Route element={<Terminal />} path="/terminal/:room" />
            </Routes>
            <Toast />
        </ThemeProvider>
    );
};

const RootRedirect: FC<{ profile?: User }> = ({ profile }) => {
    if (profile) {
        const isIterateEmployee = profile.email.includes('@iterate.no');

        if (isIterateEmployee) {
            return <Navigate replace to="/desk" />;
        }
        return <CalendarRedirect />;
    }

    return <div>Noe gikk galt</div>;
};

const CalendarRedirect: FC = () => {
    const navigate = useNavigate();
    const date = new Date();
    const year = format(date, 'yyyy');
    const week = format(date, 'w');

    useEffect(() => {
        navigate(`/calendar/${String(year)}/${String(week)}`);
    }, [navigate, year, week]);

    return null;
};

const RequireAuth: FC<{ admin?: boolean }> = ({ children, admin = false }) => {
    const profile = useProfile();

    useEffect(() => {
        let onMessage = (e: any) => {
            if (e.data === 'user_logged_in') {
                profile.refetch();
            }
        };
        window.addEventListener('message', onMessage);

        return () => {
            window.removeEventListener('message', onMessage);
        };
    });

    if (profile.error) {
        if (profile.error.response?.status === 401) {
            return <SignIn />;
        }
        return <div>{profile.error.toString()}</div>;
    }

    if (profile.isLoading) {
        return (
            <Row
                horizontal="center"
                vertical="center"
                css={css`
                    width: 100vw;
                    height: 100vh;
                `}
            >
                Loading...
            </Row>
        );
    }

    if (admin && !profile.data?.admin) {
        return <PageNotFound />;
    }

    return <>{children}</>;
};

const Toast: FC = () => {
    const { toast, clearToast } = useToastStore();

    useEffect(() => {
        if (toast) {
            const timer = setTimeout(() => {
                clearToast();
            }, 3000);

            return () => clearTimeout(timer);
        }
    }, [clearToast, toast]);

    return toast ? (
        <div
            onClick={() => clearToast()}
            css={css`
                width: 100vw;
                height: 100vh;
                position: fixed;
                top: 0;
                right: 0;
                left: 0;
                bottom: 0;
            `}
        >
            <div
                css={css`
                    position: absolute;
                    bottom: 0;
                    display: flex;
                    overflow: hidden;
                    justify-content: center;
                    align-items: center;
                    z-index: 900;
                    position: absolute;
                    left: 0;
                    right: 0;
                    margin-left: auto;
                    margin-right: auto;
                    background-color: ${toast.type === 'error'
                        ? 'red'
                        : toast.type === 'info'
                        ? 'blue'
                        : toast.type === 'success'
                        ? 'green'
                        : 'orange'};
                    color: white;
                    width: fit-content;
                    height: fit-content;
                    border-radius: 4px;
                    padding: 16px;
                    margin-bottom: 24px;
                `}
            >
                <div
                    css={css`
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        height: 3px;
                        background-color: white;
                        animation-name: ${widthKeyframe};
                        animation-duration: 3s;
                        animation-timing-function: linear;
                    `}
                />
                {toast.message}
            </div>
        </div>
    ) : null;
};

const widthKeyframe = keyframes`
from {
    width: 100%;
}

to {
    width: 0px;
}`;
