function setItem(key: string, value: unknown): boolean {
    try {
        localStorage.setItem(key, JSON.stringify(value));
        return true;
    } catch (error) {
        return false;
    }
}

function getItem<T>(key: string): T | undefined {
    try {
        const value = localStorage.getItem(key);
        const parsedValue = value && JSON.parse(value);

        return parsedValue;
    } catch (e) {
        return undefined;
    }
}

function getItemOr<T>(key: string, fallbackValue: T): T {
    try {
        const value = getItem<T>(key);
        return value || fallbackValue;
    } catch (error) {
        return fallbackValue;
    }
}

function removeItem(key: string): void {
    try {
        localStorage.removeItem(key);
    } catch (error) {}
}

export const storage = {
    setItem,
    getItem,
    getItemOr,
    removeItem,
};
