type Colors = {
    background: {
        primary: string;
        secondary: string;
    };
    border: {
        primary: string;
        bookedDesk: string;
    };
    button: {
        primary: string;
        emphasizePrimary: string;
        secondary: string;
        active: string;
        clickable: string;
        danger: string;
    };
    icon: {
        primary: string;
        active: string;
    };
    text: {
        active: string;
        primary: string;
        secondary: string;
        muted: string;
        reverse: string;
    };
};

export const lightColors = {
    background: {
        primary: '#ffffff',
        secondary: '#e5e5e5',
    },
    border: {
        primary: '#e5e5e5',
        bookedDesk: 'rgba(0,0,0,0.1)',
    },
    button: {
        primary: '#c7c7c7',
        emphasizePrimary: '#f7ffd6',
        secondary: '#92969b',
        active: '#000000',
        clickable: '#ccff00',
        danger: '#ff0000',
    },
    icon: {
        primary: '#000000',
        active: '#ffffff',
    },
    text: {
        active: '#ffffff',
        primary: '#272727',
        secondary: '#181818',
        muted: '#00000066',
        reverse: '#000000',
    },
};

export const darkColors = {
    background: {
        primary: '#272c35',
        secondary: '#404040',
    },
    border: {
        primary: '#404040',
        bookedDesk: 'rgba(255,255,255,0.1)',
    },
    button: {
        primary: '#474b53',
        emphasizePrimary: '#3d4d00',
        secondary: '#c6c8ca',
        active: '#ffffff',
        clickable: '#ccff00',
        danger: '#ff0000',
    },
    icon: {
        primary: '#ffffff',
        active: '#000000',
    },
    text: {
        active: '#272c35',
        primary: '#ffffff',
        secondary: '#272727',
        muted: '#ffffff66',
        reverse: '#ffffff',
    },
};

type Border = typeof border;

const border = {
    radius: {
        primary: '5px',
    },
};

type ScreenSize = {
    mobile: 800;
};

const screenSize: ScreenSize = {
    mobile: 800,
};

export type Theme = {
    colors: Colors;
    border: Border;
    screenSize: ScreenSize;
};

export const theme = {
    border,
    screenSize,
};
