/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import { Spinner } from '../../components/Spinner';
import { useQuickBook } from '../../network/useDesk';

export const QuickDeskBooking: FC = () => {
    const { deskId } = useParams<{ deskId: string }>();
    const { error, isLoading } = useQuickBook(deskId);
    if (isLoading) {
        return (
            <QuickBookResponseWrapper>
                <Spinner />;
            </QuickBookResponseWrapper>
        );
    }
    if (error) {
        return (
            <QuickBookResponseWrapper>
                <p
                    css={css`
                        color: red;
                        font-size: 3rem;
                        margin-bottom: 0;
                    `}
                >
                    X
                </p>
                <p>{error.response?.data.message}</p>
            </QuickBookResponseWrapper>
        );
    } else {
        return (
            <QuickBookResponseWrapper>
                <p
                    css={css`
                        color: green;
                        font-size: 3rem;
                        margin-bottom: 0;
                    `}
                >
                    ✓
                </p>
                <p>Bra booka! Denne pulten er din for i dag</p>
            </QuickBookResponseWrapper>
        );
    }
};

const QuickBookResponseWrapper = ({ children }: { children: any }) => {
    return (
        <div
            css={css`
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                height: 100vh;
            `}
        >
            {children}
        </div>
    );
};
