/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import { FC, useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useLogOut, useProfile } from '../network/useProfile';
import { ThemeTypes, useThemeStore } from '../themes/useThemeStore';
import { useOnClickOutside } from '../utils/useOnClickOutside';
import { Column, Row } from './Flex';

export const Menu: FC = () => {
    const location = useLocation();
    const profile = useProfile();
    const logOut = useLogOut();
    const { name, setTheme } = useThemeStore();
    const theme = useTheme();
    const ref = useRef<HTMLDivElement | null>(null);
    const [expanded, setExpanded] = useState(false);
    useOnClickOutside(ref, () => setExpanded(false));

    const logoutClick = () => {
        logOut.mutate();
    };

    useEffect(() => {
        if (!expanded) {
            return;
        }

        const handleKey = (event: { key: string }) => {
            if (event.key === 'Escape') {
                setExpanded(false);
            }
        };
        if (expanded) {
            document.addEventListener('keydown', handleKey);
        }
        return () => {
            document.removeEventListener('keydown', handleKey);
        };
    }, [expanded]);

    return (
        <Row
            ref={ref}
            horizontal="flex-end"
            className="menu"
            css={css`
                grid-area: menu;
                position: relative;
                z-index: 5000;
                grid-area: menu;
            `}
        >
            <button
                onClick={() => setExpanded(cs => !cs)}
                css={css`
                    outline: none;
                    img {
                        border-radius: 50%;
                        border: solid ${theme.colors.button.active} 2px;
                        width: 48px;
                        &:hover {
                            border-color: ${theme.colors.button.clickable};
                        }
                        transition: border-color 200ms ease-in-out;
                    }
                `}
            >
                <img src={profile.data?.picture} alt="menu" />
            </button>
            <Column
                css={css`
                    position: absolute;
                    overflow: hidden;
                    width: 240px;
                    top: -16px;
                    right: -8px;
                    max-height: ${expanded ? 400 : 0}px;
                    z-index: 100;
                    background-color: ${theme.colors.background.secondary};
                    border-radius: 5px;
                    transition: max-height 300ms ease-in-out;

                    a {
                        font-size: 16px;
                    }
                `}
            >
                <Column
                    css={css`
                        padding: 16px;
                    `}
                    horizontal="flex-start"
                >
                    <Row
                        horizontal="space-between"
                        vertical="center"
                        css={css`
                            width: 100%;
                            margin-bottom: 8px;
                        `}
                    >
                        <span
                            css={css`
                                max-width: 17ch;
                                overflow: hidden;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                            `}
                        >
                            {profile.data?.name}
                        </span>
                        <div
                            css={css`
                                width: 30px;
                                height: 30px;
                                border: 1px solid ${theme.colors.background.primary};
                                border-radius: 24px;
                                background-image: url('${profile.data?.picture}');
                                background-repeat: no-repeat;
                                background-size: cover;
                                background-position: center;
                            `}
                        />
                    </Row>
                    <Column
                        css={css`
                            width: 100%;
                            border-top: 1px solid ${theme.colors.background.primary};
                            border-bottom: 1px solid ${theme.colors.background.primary};
                            padding: 8px 0;
                            margin: 8px 0;
                        `}
                    >
                        <Link
                            css={css`
                                margin-bottom: 8px;
                                font-weight: ${location.pathname === '/desk' ? 'bold' : 'normal'};
                            `}
                            to="/desk"
                        >
                            Pultbooking
                        </Link>
                        <Link
                            to="/calendar"
                            css={css`
                                margin-bottom: 8px;
                                font-weight: ${location.pathname.match(/\/[0-9]{4}\/[0-9]{1,2}/g) ? 'bold' : 'normal'};
                            `}
                        >
                            Møterombooking
                        </Link>
                        {/* <Link
                            to="/lunch"
                            css={css`
                                font-weight: ${location.pathname === '/lunch' ? 'bold' : 'normal'};
                            `}
                        >
                            Lunsj
                        </Link> */}
                    </Column>
                    {profile.data?.admin && (
                        <Column
                            css={css`
                                width: 100%;
                                border-bottom: 1px solid ${theme.colors.background.primary};
                                padding: 8px 0;
                                margin-bottom: 8px;
                            `}
                        >
                            <Link
                                css={css`
                                    margin-bottom: 8px;
                                    font-weight: ${location.pathname === '/admin/desk' ? 'bold' : 'normal'};
                                `}
                                to="/admin/desk"
                            >
                                All pultbooking
                            </Link>
                            <Link
                                css={css`
                                    margin-bottom: 8px;
                                    font-weight: ${location.pathname === '/admin/rooms' ? 'bold' : 'normal'};
                                `}
                                to="/admin/rooms"
                            >
                                Rom
                            </Link>
                            <Link
                                css={css`
                                    margin-bottom: 8px;
                                    font-weight: ${location.pathname === '/admin/lunch/bookings' ? 'bold' : 'normal'};
                                `}
                                to="/admin/lunch/bookings"
                            >
                                Lunsj bookings
                            </Link>
                            <Link
                                css={css`
                                    margin-bottom: 8px;
                                    font-weight: ${location.pathname === '/admin/users' ? 'bold' : 'normal'};
                                `}
                                to="/admin/users"
                            >
                                Brukere
                            </Link>
                        </Column>
                    )}
                    <button
                        css={css`
                            white-space: nowrap;
                            margin-bottom: 8px;
                        `}
                        onClick={() => setTheme(name === 'SYSTEM' ? 'DARK' : name === 'DARK' ? 'LIGHT' : 'SYSTEM')}
                    >
                        Theme {themeNameToEmotion(name)}
                    </button>
                    <a
                        href="https://garasjen.slack.com/archives/CUQNERBMK"
                        target="_blank"
                        rel="noreferrer"
                        css={css`
                            margin-bottom: 8px;
                        `}
                    >
                        Feedback
                    </a>
                    <button
                        onClick={() => logoutClick()}
                        css={css`
                            margin-bottom: 8px;
                        `}
                    >
                        Logg ut
                    </button>
                </Column>
            </Column>
        </Row>
    );
};

function themeNameToEmotion(name: ThemeTypes) {
    switch (name) {
        case 'LIGHT':
            return '☀️';
        case 'DARK':
            return '🌙';
        case 'SYSTEM':
            return '🌗';
    }
}
