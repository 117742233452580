import { AxiosError } from 'axios';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { formatLocale } from '../utils/date';
import { meetApi } from './api';

export const equipment = {
    keyboard: 'Tastatur',
    mouse: 'Mus',
    monitor: 'Skjerm',
    designMonitor: 'Designskjerm',
};

export type Area = {
    id: number;
    name: string;
    floor: number;
    description: string | null;
    disabled: boolean;
    desks: Desk[];
};

export type EquipmentType = keyof typeof equipment;

export type Desk = {
    name: string;
    id: string;
    equipment: EquipmentType[];
};

export type Booking = {
    deskId: string;
    bookingId: number;
    reservationDate: string;
    userId: string;
    name: string;
    picture?: string;
    note?: string;
};

export const useGetDeskAreas = () => {
    return useQuery<Area[], AxiosError>(['desk_areas'], async () => {
        const res = await meetApi.get<Area[]>('api/v1/desks/areas');

        return res.data;
    });
};

export const useGetDeskBookings = ({ startDate, endDate }: { startDate: Date; endDate: Date }) => {
    return useQuery<Booking[], AxiosError>(['desk_bookings', startDate, endDate], async () => {
        const res = await meetApi.get<Booking[]>('api/v1/desks/bookings', {
            params: {
                startDate: formatLocale(startDate, 'yyyy-MM-dd'),
                endDate: formatLocale(endDate, 'yyyy-MM-dd'),
            },
        });

        return res.data;
    });
};

export const useAvailableCheck = ({
    startDate,
    endDate,
    deskId,
}: {
    startDate: Date;
    endDate: Date;
    deskId: string;
}) => {
    return useQuery<string[], AxiosError>(['available_check', startDate, endDate, deskId], async () => {
        const res = await meetApi.get<string[]>('api/v1/desks/availability', {
            params: {
                startDate: formatLocale(startDate, 'yyyy-MM-dd'),
                endDate: formatLocale(endDate, 'yyyy-MM-dd'),
                deskId,
            },
        });

        return res.data;
    });
};

type ReserveDesk = {
    deskId: string;
    startDate: Date;
    endDate: Date;
    note?: string;
};

export const useQuickBook = (deskId?: string) => {
    return useQuery<string, AxiosError>(
        ['quick-book', deskId],
        async () => {
            const res = await meetApi.post(`/api/v1/desks/quick/${deskId}`);

            return res.data;
        },
        {
            enabled: !!deskId,
            retry: false,
        }
    );
};

export const useReserveDesk = () => {
    const queryClient = useQueryClient();

    return useMutation<{ success: boolean }, AxiosError<{ success: boolean; message: string }>, ReserveDesk, unknown>(
        async (values: ReserveDesk) => {
            const res = await meetApi.post<{ success: boolean }>('/api/v1/desks', {
                ...values,
                startDate: formatLocale(values.startDate, 'yyyy-MM-dd'),
                endDate: formatLocale(values.endDate, 'yyyy-MM-dd'),
            });

            queryClient.invalidateQueries('desk_bookings');

            return res.data;
        }
    );
};

export const useBookRandomDesk = () => {
    const queryClient = useQueryClient();

    return useMutation<{ success: boolean; message: string }, AxiosError<{ success: boolean; message: string }>>(
        async () => {
            const res = await meetApi.post<{ success: boolean; message: string }>('/api/v1/desks/random');

            queryClient.invalidateQueries('random_desk_bookings');

            return res.data;
        }
    );
};

export type ConfirmBookingStatus = 'confirmed' | 'error' | 'no_bookings_found';

interface ConfirmBookingResponse {
    status: ConfirmBookingStatus;
    bookedDesks?: string[];
}

export const useConfirmTodaysDeskBooking = () => {
    return useMutation<ConfirmBookingResponse, AxiosError<ConfirmBookingResponse>>(async () => {
        const res = await meetApi.post<ConfirmBookingResponse>('/api/v1/desks/confirm');
        return res.data;
    });
};

export type RemoveBookingStatus = 'ok' | 'error';

interface RemoveBookingResponse {
    status: RemoveBookingStatus;
}

export const useRemoveTodaysDeskBooking = () => {
    return useMutation<RemoveBookingResponse, AxiosError<ConfirmBookingResponse>>(async () => {
        const res = await meetApi.delete<RemoveBookingResponse>('/api/v1/desks/delete-booking');
        return res.data;
    });
};

export const useUpdateBooking = () => {
    const queryClient = useQueryClient();

    return useMutation<
        { success: boolean },
        AxiosError<{ success: boolean; message: string }>,
        { bookingId: number; note: string },
        unknown
    >(async (values: { bookingId: number; note: string }) => {
        const res = await meetApi.put<{ success: boolean }>(`/api/v1/desks/${values.bookingId}`, {
            note: values.note || null,
        });

        queryClient.invalidateQueries('desk_bookings');

        return res.data;
    });
};

type DeleteDesk = {
    bookingId: number;
};

export const useDeleteDeskReservation = () => {
    const queryClient = useQueryClient();

    return useMutation<{ success: boolean }, AxiosError<{ success: boolean; message: string }>, DeleteDesk, unknown>(
        async (values: DeleteDesk) => {
            const res = await meetApi.delete<{ success: boolean }>('/api/v1/desks', {
                params: {
                    bookingId: values.bookingId,
                },
            });

            queryClient.invalidateQueries('desk_bookings');
            queryClient.invalidateQueries('all_bookings');

            return res.data;
        }
    );
};

// Admin
export const useGetAllBookings = (startDate: Date, endDate: Date, name: string) => {
    return useQuery<Booking[], AxiosError>(
        ['all_bookings', startDate, endDate, name],
        async () => {
            const res = await meetApi.get<Booking[]>('api/v1/admin/desks/bookings', {
                params: {
                    startDate: formatLocale(startDate, 'yyyy-MM-dd'),
                    endDate: formatLocale(endDate, 'yyyy-MM-dd'),
                    name,
                },
            });
            return res.data;
        },
        {
            enabled: !name ? true : name.length >= 2,
            refetchOnWindowFocus: false,
            retry: false,
        }
    );
};
