/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import { endOfDay, isBefore, isSameDay, startOfDay } from 'date-fns';
import { FC, useEffect, useState } from 'react';
import { Column, Row } from '../../../components/Flex';
import {
    Booking,
    Desk as DeskType,
    useDeleteDeskReservation,
    useReserveDesk,
    useUpdateBooking,
} from '../../../network/useDesk';
import { LunchBooking, useBookLunch } from '../../../network/useLunch';
import { User } from '../../../network/useProfile';
import { useToastStore } from '../../../utils/store/useToastStore';
import { BookingModal } from './BookingModal';
import { Button } from './Button';
import { DesignMonitor, Keyboard, Monitor, Mouse } from './Equipment';

export const BookingButton: FC<{
    desk: DeskType;
    date: Date;
    booking?: Booking;
    profile?: User;
    lunchBookings: LunchBooking[];
    today?: boolean;
}> = ({ desk, profile, date, booking, lunchBookings, today }) => {
    const theme = useTheme();
    const disabled = isBefore(date, startOfDay(new Date()));
    const isViewersBooking = booking && booking?.userId === profile?.sub;
    const deleteDeskReservation = useDeleteDeskReservation();
    const reserveDesk = useReserveDesk();
    const { setToast } = useToastStore();
    const [note, setNote] = useState(booking?.note ?? '');
    const [bookingConfirmedModalVisible, setBookingConfirmedModalVisible] = useState(false);
    const [designerDeskInfoShown, setDesignerDeskInfoShown] = useState<boolean>(false);
    const updateBooking = useUpdateBooking();
    const lunchBooking = useBookLunch();
    const currentLunchBooking = lunchBookings.find(booking => isSameDay(new Date(booking.date), date));
    const [bookedLunch, setBookedLunch] = useState<boolean>(Boolean(currentLunchBooking));

    const handleDeleteDeskReservation = () => {
        booking &&
            deleteDeskReservation.mutate({
                bookingId: booking.bookingId,
            });
    };

    const handleUpdateDeskReservation = async () => {
        if (booking) {
            await updateBooking.mutateAsync({
                bookingId: booking.bookingId,
                note,
            });

            setBookingConfirmedModalVisible(false);
        }
    };

    useEffect(() => {
        if (reserveDesk.isError) {
            setToast({
                type: 'error',
                message: reserveDesk.error.response?.data.message || 'Her skjedde det noe feil...',
            });
        }
    }, [reserveDesk.error?.response?.data.message, reserveDesk.isError, setToast]);

    useEffect(() => {
        if (lunchBooking.isError) {
            setToast({
                type: 'error',
                message: lunchBooking.error.response?.data?.message || 'Her skjedde det noe feil...',
            });
        }
    }, [lunchBooking.isError, lunchBooking.error, setToast]);

    const handleReserveDesk = async () => {
        if (desk.equipment.includes('designMonitor') && !designerDeskInfoShown) {
            setDesignerDeskInfoShown(true);
            setToast({
                type: 'warning',
                message:
                    'Denne pulten er prioritert for designere, har du mulighet for å velge en annen?:) Eller trykk igjen for å bekrefte',
            });
        } else {
            const response = await reserveDesk.mutateAsync({
                deskId: desk.id,
                startDate: startOfDay(date),
                endDate: endOfDay(date),
            });

            if (response.success) {
                setBookingConfirmedModalVisible(true);
            }
        }
    };

    return (
        <div
            css={css`
                background-color: ${booking
                    ? isViewersBooking
                        ? theme.colors.button.active
                        : theme.colors.background.primary
                    : today
                    ? theme.colors.button.emphasizePrimary
                    : theme.colors.button.primary};
                position: relative;
                margin-bottom: 8px;
                border-radius: 4px;
                border: ${booking ? `1px solid ${theme.colors.border.bookedDesk}` : ''};
            `}
        >
            <Button
                disabled={disabled}
                onClick={() => {
                    isViewersBooking ? handleDeleteDeskReservation() : handleReserveDesk();
                }}
                css={css`
                    padding: 4px;
                    position: relative;
                    height: 48px;
                    width: 100%;
                    background-color: transparent;

                    @media screen and (max-width: 800px) {
                        margin-bottom: 5px;
                    }
                `}
            >
                {booking?.picture && (
                    <div
                        title={booking?.name}
                        css={css`
                            position: absolute;
                            left: 6px;
                            margin-top: 4px;
                            width: 32px;
                            height: 32px;
                            border-radius: 32px;
                            background-image: ${isViewersBooking ? '' : `url('${booking.picture}')`};
                            background-repeat: no-repeat;
                            background-size: cover;
                            background-position: center;
                        `}
                    >
                        {isViewersBooking && (
                            <div
                                css={css`
                                    color: ${theme.colors.text.active};
                                    margin-top: 6px;
                                    display: flex;
                                `}
                            >
                                <span>✓</span>
                                <span>{Boolean(bookedLunch) ? '🥪' : ' '}</span>
                            </div>
                        )}
                    </div>
                )}
                <Column
                    horizontal="flex-end"
                    justify="space-between"
                    css={css`
                        height: 100%;
                    `}
                >
                    <div
                        css={css`
                            color: ${booking ? theme.colors.text.active : theme.colors.text.primary};
                            font-size: 0.8rem;
                            font-weight: 500;
                        `}
                    >
                        {desk.name}
                    </div>
                    <Row
                        css={css`
                            svg:not(:last-of-type) {
                                margin-right: 8px;
                            }
                        `}
                    >
                        {(isViewersBooking || !booking) &&
                            desk.equipment.map(equipment => {
                                switch (equipment) {
                                    case 'keyboard':
                                        return <Keyboard key={equipment} active={isViewersBooking} />;
                                    case 'mouse':
                                        return <Mouse key={equipment} active={isViewersBooking} />;
                                    case 'monitor':
                                        return <Monitor key={equipment} active={isViewersBooking} />;
                                    case 'designMonitor':
                                        return <DesignMonitor key={equipment} active={isViewersBooking} />;
                                    default:
                                        return null;
                                }
                            })}
                    </Row>
                </Column>
            </Button>
            {booking?.note && (
                <div
                    css={css`
                        opacity: ${disabled ? 0.4 : 1};
                        color: ${isViewersBooking ? theme.colors.text.active : theme.colors.text.primary};
                        padding: 5px;
                    `}
                >
                    {booking.note}
                </div>
            )}
            {bookingConfirmedModalVisible && (
                <BookingModal
                    closeModal={() => setBookingConfirmedModalVisible(false)}
                    desk={desk}
                    date={date}
                    note={note}
                    updateNote={(note: string) => setNote(note)}
                    bookingLoading={updateBooking.isLoading}
                    handleUpdateDeskReservation={handleUpdateDeskReservation}
                    currentLunchBooking={currentLunchBooking}
                    setBookedLunch={() => setBookedLunch(currentState => !currentState)}
                    bookedLunch={bookedLunch}
                />
            )}
        </div>
    );
};
