/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { areIntervalsOverlapping, isToday } from 'date-fns';
import { darken } from 'polished';
import { FC, useEffect, useRef, useState } from 'react';
import { Column } from '../../../../components/Flex';
import { Event as EventType } from '../../../../network/useCalendar';
import { NewEvent } from '../../Calendar';
import { BookingModal } from '../BookingModal';
import { Event } from './Event';

export const Day: FC<{
    date: Date;
    events: EventType[];
    rooms: Array<{ id: string; name: string }>;
    userEmail: string;
    contentWrapperScrollTop: number;
    saveEvent: (newEvent: NewEvent) => void;
    refetchCalendar: () => void;
    deleteEvent: (eventId: string) => void;
}> = ({ date, events, rooms, contentWrapperScrollTop, saveEvent, userEmail, refetchCalendar, deleteEvent }) => {
    const theme = useTheme();
    const [isDrawing, setIsDrawing] = useState(false);
    const [drawStart, setDrawStart] = useState<number | null>(null);
    const [drawEnd, setDrawEnd] = useState<number | null>(null);
    const [showBookingModal, setShowBookingModal] = useState(false);
    const [activeEvent, setActiveEvent] = useState<EventType | null>(null);
    const dayRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if (activeEvent?.id) {
            setShowBookingModal(true);
        }
    }, [activeEvent]);

    const [start, end] =
        drawStart && drawEnd && drawStart <= drawEnd ? [drawStart, drawEnd + 15] : [drawEnd, drawStart];
    const setStart = (newStart: number) => {
        setDrawStart(newStart);
        if (end) {
            setDrawEnd(end - 15);
        }
    };
    const setEnd = (newEnd: number) => {
        setDrawStart(start);
        setDrawEnd(newEnd - 15);
    };

    const mouseDown = (e: any) => {
        if (showBookingModal || e.target.id === 'calendar-event') {
            return;
        }

        setIsDrawing(true);
        let time = eventToTime(e, dayRef.current?.offsetTop || 0, contentWrapperScrollTop);
        setDrawStart(time.hour * 60 + time.quarter * 15);
        setDrawEnd(time.hour * 60 + time.quarter * 15 + 15);

        // Connect mouse up to the window and not the component so that this works also when
        // mouse up happens in another component
        window.addEventListener(
            'mouseup',
            () => {
                setIsDrawing(false);
                setShowBookingModal(true);
            },
            { once: true }
        );
    };

    const mouseMove = (e: any) => {
        if (isDrawing) {
            let time = eventToTime(e, dayRef.current?.offsetTop || 0, contentWrapperScrollTop);
            let time_in_quarters = time.hour * 60 + time.quarter * 15;
            setDrawEnd(time_in_quarters);
        }
    };

    return (
        <Column
            onMouseDown={mouseDown}
            onMouseMove={mouseMove}
            id={isToday(date) ? 'day-today' : ''}
            ref={dayRef}
            flex={1}
            css={css`
                position: relative;
                min-width: calc(100vw / 7 - 12px);
                border-color: ${theme.colors.border.primary};
                border-style: solid;
                border-width: 0 1px 0 0;
                cursor: ${isDrawing ? 'move' : 'default'};

                @media screen and (max-width: ${theme.screenSize.mobile}px) {
                    min-width: 70vw;
                }
            `}
        >
            {start && end && (
                <Placeholder
                    style={{
                        top: start,
                        height: Math.max((end || 0) - start, 15),
                        zIndex: 1,
                    }}
                >
                    <div style={{ color: 'white' }}>
                        <FormatTime minutes={start}></FormatTime> - <FormatTime minutes={end}></FormatTime>
                    </div>
                </Placeholder>
            )}
            {showBookingModal && (
                <BookingModal
                    start={start}
                    end={end}
                    rooms={rooms}
                    date={date}
                    setStart={setStart}
                    setEnd={setEnd}
                    saveEvent={saveEvent}
                    userEmail={userEmail}
                    refetchCalendar={refetchCalendar}
                    closeModal={() => {
                        setDrawStart(null);
                        setDrawEnd(null);
                        setShowBookingModal(false);
                        setActiveEvent(null);
                    }}
                    deleteEvent={deleteEvent}
                    eventId={activeEvent?.id}
                />
            )}
            {events.map((event: EventType, i: number) => {
                const eventsWithSameWindow = eventsInTheSameTimeWindow(events, event);
                const column = eventsWithSameWindow.findIndex(e => `${e.id}-${e.room}` === `${event.id}-${event.room}`);

                return (
                    <Event
                        key={i}
                        day={date}
                        event={event}
                        columns={eventsWithSameWindow.length}
                        column={column}
                        setActiveEvent={setActiveEvent}
                    />
                );
            })}
        </Column>
    );
};

function eventsInTheSameTimeWindow(events: EventType[], currentEvent: EventType): EventType[] {
    return events.filter(event =>
        areIntervalsOverlapping(
            {
                start: new Date(currentEvent.start),
                end: new Date(currentEvent.end),
            },
            {
                start: new Date(event.start),
                end: new Date(event.end),
            },
            {
                inclusive: false,
            }
        )
    );
}

const Placeholder = styled.div`
    position: absolute;
    left: 0;
    right: 0;
    background-color: #52e7be;
    color: white;
    padding: 8px;
    border: 1px solid ${darken(0.3, '#52e7be')};
    border-radius: 4px;
    overflow: hidden;
`;

const FormatTime: FC<{ minutes: number }> = ({ minutes }) => {
    let hours = Math.floor(minutes / 60);
    minutes = minutes % 60;

    return <span>{`${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`}</span>;
};

function eventToTime(e: any, offsetTop: number, contentWrapperScrollTop: number) {
    let y = e.pageY - offsetTop + contentWrapperScrollTop;
    let quarter = Math.floor(y / 15);
    let hour = Math.floor(quarter / 4);
    quarter = quarter % 4;

    return { hour, quarter };
}
