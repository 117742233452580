import { addMinutes, differenceInMinutes, isAfter, isBefore } from 'date-fns';
import { Event } from '../../network/useCalendar';
import { formatLocale } from '../../utils/date';

export function backgroundColor(timeLeftCurrentEvent: number) {
    if (timeLeftCurrentEvent > 10) {
        return '#3333FF';
    }

    if (timeLeftCurrentEvent > -1) {
        return '#FF0033';
    }

    return '#000000';
}

export function availableUntilText(timeUntilNextEvent: number, events: Event[]): string {
    if (timeUntilNextEvent < 0) {
        return 'Resten av dagen';
    }

    const nextEvent = events.find(event => isAfter(event.start, new Date()));

    if (timeUntilNextEvent > 60 && nextEvent) {
        return `TIL ${formatLocale(nextEvent.start, 'HH:mm')}`;
    } else {
        return `${timeUntilNextEvent} MIN`;
    }
}

export function takenUntilText(timeLeftCurrentEvent: number): string {
    if (timeLeftCurrentEvent > 60) {
        return `TIL ${formatLocale(addMinutes(new Date(), timeLeftCurrentEvent), 'HH:mm')}`;
    } else {
        return `${timeLeftCurrentEvent} MIN`;
    }
}

export function calculateTimeUntilNextEvent(events: Event[]): number {
    if (events.length === 0) {
        return -1;
    }

    const nextEvent = events.find(event => isAfter(event.start, new Date()));

    return nextEvent ? differenceInMinutes(nextEvent.start, new Date()) : -1;
}

export function calculateTimeLeftCurrentEvent(events: Event[]): number {
    const currentEvent = events.find(event => isAfter(new Date(), event.start) && isBefore(new Date(), event.end));

    return currentEvent ? differenceInMinutes(currentEvent.end, new Date()) : -1;
}
