/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { isAfter } from 'date-fns';
import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Column, Row } from '../../components/Flex';
import { CalendarRes } from '../../network/useCalendar';
import { useCalendarRoom } from '../../network/useTerminal';
import { formatLocale } from '../../utils/date';
import { BookingButtons } from './BookingButtons';
import { Status } from './Status';
import { UpcomingEvents } from './UpcomingEvents';
import { backgroundColor, calculateTimeLeftCurrentEvent, calculateTimeUntilNextEvent } from './utils';

export const Terminal: FC = () => {
    const { room } = useParams<{
        room: string;
    }>();
    const { data, isLoading, error, refetch } = useCalendarRoom(room!);
    const [rotation, setRotation] = useState(180);

    const handleRotateClick = () => {
        setRotation(cs => (cs === 0 ? 180 : 0));
    };

    if (error) {
        return (
            <div
                onClick={handleRotateClick}
                css={css`
                    transform: rotate(${rotation}deg);
                `}
            >
                {error.toString()}
            </div>
        );
    }

    if (isLoading) {
        return (
            <Row
                onClick={handleRotateClick}
                horizontal="center"
                vertical="center"
                css={css`
                    width: 100vw;
                    height: 100vh;
                    transform: rotate(${rotation}deg);
                `}
            >
                Loading...
            </Row>
        );
    }

    if (data) {
        return (
            <TerminalWithData
                calendar={data}
                handleRotateClick={handleRotateClick}
                rotation={rotation}
                refetch={() => refetch()}
            />
        );
    }

    return null;
};

const TerminalWithData: FC<{
    calendar: CalendarRes;
    rotation: number;
    refetch: () => void;
    handleRotateClick: () => void;
}> = ({ calendar, rotation, refetch, handleRotateClick }) => {
    const { events, id: roomId } = calendar;
    const upcomingEvents = events.filter(event => isAfter(event.start, new Date()));

    const [timeUntilNextEvent, setTimeUntilNextEvent] = useState(calculateTimeUntilNextEvent(upcomingEvents));
    const [timeLeftCurrentEvent, setTimeLeftCurrentEvent] = useState(calculateTimeLeftCurrentEvent(events));

    useEffect(() => {
        const interval = setInterval(() => {
            setTimeUntilNextEvent(calculateTimeUntilNextEvent(upcomingEvents));
            setTimeLeftCurrentEvent(calculateTimeLeftCurrentEvent(events));
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, [events, upcomingEvents]);

    return (
        <Column
            flex={1}
            css={css`
                width: 100vw;
                height: 100vh;
                background-color: ${backgroundColor(timeLeftCurrentEvent)};
                padding: 32px;
                transform: rotate(${rotation}deg);
            `}
            horizontal="flex-start"
        >
            <Row
                onClick={handleRotateClick}
                horizontal="flex-end"
                css={css`
                    color: white;
                    width: 100%;
                `}
            >
                {formatLocale(new Date(), 'HH:mm')}
            </Row>
            <Status
                timeLeftCurrentEvent={timeLeftCurrentEvent}
                timeUntilNextEvent={timeUntilNextEvent}
                upcomingEvents={upcomingEvents}
            />
            <BookingButtons events={events} room={roomId} refetch={refetch} />
            <UpcomingEvents events={upcomingEvents} />
        </Column>
    );
};
