import { AxiosError } from 'axios';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { formatLocale } from '../utils/date';
import { meetApi } from './api';

export const useBookLunch = () => {
    const queryClient = useQueryClient();

    return useMutation<
        { success: boolean },
        AxiosError<{ success: boolean; message: string }>,
        { date: Date },
        unknown
    >(async (values: { date: Date }) => {
        const res = await meetApi.post<{ success: boolean }>('/api/v1/lunch', {
            date: values.date.toDateString(),
        });

        queryClient.invalidateQueries(['lunch_bookings']);

        return res.data;
    });
};

export const useBookMultipleLunchDates = () => {
    const queryClient = useQueryClient();

    return useMutation<
        { success: boolean },
        AxiosError<{ success: boolean; message: string }>,
        { dates: Date[] },
        unknown
    >(async (values: { dates: Date[] }) => {
        const res = await meetApi.post<{ success: boolean }>('/api/v1/lunch/multiple_days', {
            dates: values.dates,
        });

        queryClient.invalidateQueries(['lunch_bookings']);

        return res.data;
    });
};

export const useDeleteBookedLunch = () => {
    const queryClient = useQueryClient();

    return useMutation<
        { success: boolean },
        AxiosError<{ success: boolean; message: string }>,
        { id: number },
        unknown
    >(async (values: { id: number }) => {
        const res = await meetApi.delete<{ success: boolean }>('/api/v1/lunch', {
            params: { bookingId: values.id },
        });

        queryClient.invalidateQueries(['lunch_bookings']);

        return res.data;
    });
};

export const useWishLunchItem = () => {
    return useMutation<
        { success: boolean },
        AxiosError<{ success: boolean; message: string }>,
        { url: string },
        unknown
    >(async (values: { url: string }) => {
        const res = await meetApi.post<{ success: boolean }>('/api/v1/lunch_wish', {
            url: values.url,
        });

        return res.data;
    });
};

export type LunchBooking = {
    id: number;
    created_at: string;
    updated_at: string;
    date: string;
    user_id: string;
    amount: string;
};

export const useGetMyLunchBookings = (startDate: Date, endDate: Date) => {
    return useQuery<LunchBooking[]>('My lunch bookings', async () => {
        const res = await meetApi.get('/api/v1/lunch', {
            params: {
                startDate: formatLocale(startDate, 'yyyy-MM-dd'),
                endDate: formatLocale(endDate, 'yyyy-MM-dd'),
            },
        });

        return res.data;
    });
};

export const useGetAllLunchBookings = (startDate: Date, endDate: Date) => {
    return useQuery<AdminLunchBooking[]>(['admin_lunch_bookings', startDate, endDate], async () => {
        const res = await meetApi.get('/api/v1/admin/lunch/bookings', {
            params: {
                startDate: formatLocale(startDate, 'yyyy-MM-dd'),
                endDate: formatLocale(endDate, 'yyyy-MM-dd'),
            },
        });

        return res.data;
    });
};

export type AdminLunchBooking = {
    date: string;
    sub: string;
    name: string;
    email: string;
    amount: number;
    company: string;
};
