/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import { FC, useState } from 'react';
import { Column, Row } from '../../components/Flex';
import { Header } from '../../components/Header';
import { Modal } from '../../components/Modal';
import { Switch } from '../../components/Swich';
import { AdminRoom, useEditRoom, useGetAllRooms } from '../../network/useAdmin';
import { EquipmentType, equipment } from '../../network/useDesk';
import { Button } from '../desk/components/Button';

export const Rooms: FC = () => {
    const { data, isLoading } = useGetAllRooms();
    const theme = useTheme();

    return (
        <div>
            <Header date={new Date()} />
            <Column
                css={css`
                    width: 100%;
                    padding: 0px 24px;
                    @media screen and (max-width: 800px) {
                        padding: 0px 6px;
                        margin-top: 100px;
                    }
                    padding-top: 100px;
                `}
            >
                <h1>Rom</h1>
                {isLoading && <div>Loading...</div>}
                {data && (
                    <table
                        css={css`
                            margin-top: 32px;
                            margin-bottom: 64px;
                            border-collapse: collapse;

                            th,
                            td {
                                padding-left: 4px;
                                text-align: left;
                            }

                            tbody {
                                tr {
                                    &:hover {
                                        td {
                                            background-color: ${theme.colors.background.secondary};
                                        }
                                    }
                                    td {
                                        padding-top: 8px;
                                        padding-bottom: 8px;
                                    }
                                }
                            }
                        `}
                    >
                        <thead>
                            <tr>
                                <th>Rom</th>
                                <th>Beskrivelse</th>
                                <th>Etasje</th>
                                <th>Aktivt</th>
                                <th />
                            </tr>
                        </thead>
                        <tbody>
                            {data.map(room => (
                                <RoomRow room={room} key={room.name} />
                            ))}
                        </tbody>
                    </table>
                )}
            </Column>
        </div>
    );
};

const RoomRow: FC<{ room: AdminRoom }> = ({ room }) => {
    const [showModal, setShowModal] = useState(false);

    return (
        <>
            <tr>
                <td>{room.name}</td>
                <td>{room.description}</td>
                <td>{room.floor}</td>
                <td>{room.disabled ? 'Ikke aktivt' : 'Aktivt'}</td>
                <td
                    css={css`
                        text-align: right !important;
                        padding-right: 16px;
                    `}
                >
                    <Button onClick={() => setShowModal(true)}>🖋</Button>
                </td>
            </tr>
            <EditRoomModal
                showModal={showModal}
                setShowModal={(showModal: boolean) => setShowModal(showModal)}
                room={room}
            />
        </>
    );
};

const EditRoomModal: FC<{
    showModal: boolean;
    setShowModal: (showModal: boolean) => void;
    room: AdminRoom;
}> = ({ showModal, setShowModal, room: initRoom }) => {
    const editRoom = useEditRoom();
    const [room, setRoom] = useState(initRoom);
    const theme = useTheme();

    const handleUpdateRoom = () => {
        console.log(initRoom);
        console.log(room);

        editRoom.mutate(room);

        if (editRoom.isSuccess) {
            setShowModal(false);
        }
    };

    return showModal ? (
        <Modal closeModal={() => setShowModal(false)}>
            <h2>Rediger {room.name}</h2>
            <Row
                align="center"
                css={css`
                    margin-bottom: 16px;
                `}
            >
                Er aktivt
                <div style={{ width: 24 }} />
                <Switch checked={!room.disabled} onChange={() => setRoom(cs => ({ ...cs, disabled: !cs.disabled }))} />
            </Row>
            <label
                css={css`
                    width: 100%;
                    margin-bottom: 16px;
                `}
            >
                <div>Navn</div>
                <input type="text" value={room.name} onChange={e => setRoom(cs => ({ ...cs, name: e.target.value }))} />
            </label>
            <label
                css={css`
                    width: 100%;
                    margin-bottom: 16px;
                `}
            >
                Beskrivelse <br />
                <input
                    type="text"
                    value={room.description}
                    onChange={e => setRoom(cs => ({ ...cs, description: e.target.value }))}
                />
            </label>
            <label
                css={css`
                    width: 100%;
                    margin-bottom: 16px;
                `}
            >
                Etasje <br />
                <input
                    type="number"
                    value={room.floor}
                    onChange={e =>
                        setRoom(cs => ({
                            ...cs,
                            floor: Number(e.target.value),
                        }))
                    }
                />
            </label>
            <table>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Navn</th>
                        <th>Utstyr</th>
                        <th>Aktivt</th>
                    </tr>
                </thead>
                <tbody>
                    {room.desks.map((desk, index) => (
                        <tr key={desk.id}>
                            <td>{desk.id}</td>
                            <td>
                                <input
                                    type="text"
                                    value={room.desks[index].name}
                                    css={css`
                                        width: 60px;
                                    `}
                                />
                            </td>
                            <td>
                                <div>
                                    {Object.entries(equipment).map(([key, value]) => (
                                        <div>
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    checked={desk.equipment.includes(key as EquipmentType)}
                                                    onClick={() =>
                                                        setRoom(cs => ({
                                                            ...cs,
                                                            desks: cs.desks.map((d, i) => {
                                                                if (index === i) {
                                                                    if (d.equipment.includes(key as EquipmentType)) {
                                                                        return {
                                                                            ...d,
                                                                            equipment: d.equipment.filter(
                                                                                e => e !== (key as EquipmentType)
                                                                            ),
                                                                        };
                                                                    }
                                                                    return {
                                                                        ...d,
                                                                        equipment: [
                                                                            ...d.equipment,
                                                                            key as EquipmentType,
                                                                        ],
                                                                    };
                                                                }
                                                                return d;
                                                            }),
                                                        }))
                                                    }
                                                />{' '}
                                                {value}
                                            </label>
                                        </div>
                                    ))}
                                </div>
                            </td>
                            <td>
                                <Switch
                                    checked={!desk.disabled}
                                    onChange={() =>
                                        setRoom(cs => ({
                                            ...cs,
                                            desks: cs.desks.map((d, i) => {
                                                if (index === i) {
                                                    return {
                                                        ...d,
                                                        disabled: !d.disabled,
                                                    };
                                                }
                                                return d;
                                            }),
                                        }))
                                    }
                                />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <div
                css={css`
                    height: 32px;
                `}
            />
            <Row justify="space-between">
                <Button onClick={() => setShowModal(false)}>Avbryt</Button>
                <Button
                    css={css`
                        background-color: ${theme.colors.button.clickable};
                    `}
                    onClick={handleUpdateRoom}
                >
                    Lagre
                </Button>
            </Row>
        </Modal>
    ) : null;
};
