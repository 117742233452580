/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import React, { FC } from 'react';

export const Switch: FC<{ checked: boolean; onChange: () => void }> = ({ checked, onChange }) => {
    const theme = useTheme();

    return (
        <label
            css={css`
                position: relative;
                display: inline-block;
                width: 60px;
                height: 34px;
            `}
        >
            <input
                css={css`
                    opacity: 0;
                    width: 0;
                    height: 0;

                    &:checked + span {
                        background-color: ${theme.colors.button.clickable};
                    }

                    &:focus + span {
                        box-shadow: 0 0 1px ${theme.colors.button.clickable};
                    }

                    &:checked + span:before {
                        -webkit-transform: translateX(26px);
                        -ms-transform: translateX(26px);
                        transform: translateX(26px);
                    }
                `}
                type="checkbox"
                checked={checked}
                onChange={onChange}
            />
            <span
                css={css`
                    position: absolute;
                    cursor: pointer;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background-color: ${theme.colors.background.secondary};
                    -webkit-transition: 0.4s;
                    transition: 0.4s;
                    border-radius: 34px;

                    &:before {
                        position: absolute;
                        content: '';
                        height: 26px;
                        width: 26px;
                        left: 4px;
                        bottom: 4px;
                        background-color: white;
                        -webkit-transition: 0.4s;
                        transition: 0.4s;
                        border-radius: 50%;
                    }
                `}
            />
        </label>
    );
};
