import { createContext, FC, useContext } from 'react';
import { storage } from '../utils/storage';
import { darkColors, lightColors, theme } from './theme';
import { PREFERRED_COLOR_SCHEME, usePrefersColorScheme } from './usePrefersColorScheme';

export type ThemeTypes = 'LIGHT' | 'DARK' | 'SYSTEM';

const ThemeContext = createContext({
    name: 'LIGHT',
    theme: {
        ...theme,
        colors: lightColors,
    },
    setTheme: (newTheme: ThemeTypes) => {},
});

export const useThemeStore = () => {
    const context = useContext(ThemeContext);

    if (!context) {
        throw new Error('You need to use useTheme inside a ThemeProvider');
    }

    const { name, theme, setTheme } = context;

    const themeName = name as ThemeTypes;

    return {
        name: themeName,
        theme,
        setTheme,
    };
};

export const MeetThemeProvider: FC = ({ children }) => {
    const currentTheme = usePrefersColorScheme();

    const handleSetTheme = (newTheme: ThemeTypes) => {
        storage.setItem(PREFERRED_COLOR_SCHEME, newTheme);
        currentTheme.setTheme(newTheme);
    };

    return (
        <ThemeContext.Provider
            value={{
                name: currentTheme.name,
                theme:
                    currentTheme.theme === 'DARK'
                        ? {
                              ...theme,
                              colors: darkColors,
                          }
                        : {
                              ...theme,
                              colors: lightColors,
                          },
                setTheme: handleSetTheme,
            }}
        >
            {children}
        </ThemeContext.Provider>
    );
};
