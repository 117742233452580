/** @jsxImportSource @emotion/react */
import { css, keyframes, useTheme } from '@emotion/react';

type SpinnerProps = {
    size?: number;
    color?: string;
};

const spin = keyframes`
  0% {
    transform: rotate(0deg)
  }
  100% {
    transform: rotate(360deg)

  }
`;

export const Spinner = ({ size = 20, color }: SpinnerProps) => {
    const theme = useTheme();
    color = color || theme.colors.text.primary;
    const borderWidth = size / 6;

    return (
        <div
            css={css`
                width: ${size}px;
                height: ${size}px;
                border-radius: ${size}px;
                border: ${borderWidth}px solid transparent;
                border-top: ${borderWidth}px solid ${color};
                border-right: ${borderWidth}px solid ${color};
                animation: ${spin} 600ms linear infinite;
            `}
        />
    );
};
