import React, { FC, useEffect } from 'react';
import styled from '@emotion/styled';

export const Modal: FC<ModalType> = ({ closeModal, children, width, height }) => {
    useEffect(() => {
        function handleKey(event: any) {
            if (event.key === 'Escape') {
                closeModal();
            }
        }

        document.addEventListener('keydown', handleKey);
        return () => {
            document.removeEventListener('keydown', handleKey);
        };
    }, [closeModal]);

    return (
        <ModalWrapper
            onClick={ev => {
                if ((ev.target as HTMLDivElement).closest('#modal-inner') !== null) {
                    // Don't close modal if the click is inside Inner
                    return;
                }
                closeModal();
            }}
        >
            <ModalInner id="modal-inner" width={width} height={height}>
                {children}
            </ModalInner>
        </ModalWrapper>
    );
};

type ModalType = {
    closeModal: () => void;
    width?: number | string;
    height?: number | string;
};

const ModalWrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
`;

const ModalInner = styled.div<{
    width?: number | string;
    height?: number | string;
}>`
    width: ${props => (props.width ? (typeof props.width === 'number' ? `${props.width}px` : props.width) : '500px')};
    height: ${props =>
        props.height ? (typeof props.height === 'number' ? `${props.height}px` : props.height) : 'auto'};
    max-height: 90vh;
    overflow-y: auto;
    padding: 20px;
    background-color: white;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: black;
`;
