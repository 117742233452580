/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { addMinutes, isWithinInterval } from 'date-fns';
import { FC } from 'react';
import { Column, Row } from '../../components/Flex';
import { Spinner } from '../../components/Spinner';
import { Event } from '../../network/useCalendar';
import { useBookRoom } from '../../network/useTerminal';
import { calculateTimeUntilNextEvent } from './utils';

export const BookingButtons: FC<{
    events: Event[];
    room: string;
    refetch: () => void;
}> = ({ events, room, refetch }) => {
    const bookRoom = useBookRoom();
    const roomIsAvailable =
        events.filter(event =>
            isWithinInterval(new Date(), {
                start: event.start,
                end: event.end,
            })
        ).length === 0;
    const minutesUntilNextEvent = calculateTimeUntilNextEvent(events);

    const handleBookRoom = async (room: string, start: string, end: string) => {
        await bookRoom.mutateAsync({
            room: room,
            start: start,
            end: end,
        });

        refetch();
    };

    if (!roomIsAvailable) {
        return null;
    }

    return (
        <Column>
            {bookRoom.isLoading && <Spinner color="white" />}
            <Row
                flex={1}
                css={css`
                    max-width: calc(100vw - 32px);
                    flex-wrap: nowrap;
                    overflow-x: auto;
                `}
            >
                <BookButton
                    onClick={() =>
                        handleBookRoom(
                            room,
                            new Date().toISOString(),
                            addMinutes(
                                new Date(),
                                minutesUntilNextEvent !== -1 && minutesUntilNextEvent < 30 ? minutesUntilNextEvent : 30
                            ).toISOString()
                        )
                    }
                >
                    Book i {minutesUntilNextEvent !== -1 && minutesUntilNextEvent < 30 ? minutesUntilNextEvent : 30} min
                </BookButton>
                {(minutesUntilNextEvent === -1 || minutesUntilNextEvent > 30) && (
                    <BookButton>
                        Book i {minutesUntilNextEvent !== -1 && minutesUntilNextEvent < 60 ? minutesUntilNextEvent : 60}{' '}
                        min
                    </BookButton>
                )}
            </Row>
        </Column>
    );
};

const BookButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    padding-right: 20px;
    padding-left: 20px;
    font-size: 20px;
    height: 50px;
    border-radius: 5px;
    background-color: white;
    color: black;
    cursor: pointer;
    margin-right: 20px;
`;
