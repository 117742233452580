/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { random, shuffle } from 'lodash';
import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { meetApiAddress } from '../../network/api';
import googleG from './illustrations/google-g.svg';
import iterateLogo from './illustrations/iterate-logo.gif';

function showLoginModal() {
    window.open(`${meetApiAddress}/login`, '_blank');
}

export const SignIn: FC = () => {
    const navigate = useNavigate();

    return (
        <Wrapper>
            <div
                onClick={() => navigate('/terminal')}
                css={css`
                    position: absolute;
                    top: 0;
                    right: 0;
                    width: 300px;
                    height: 300px;
                    z-index: 101;
                `}
            />
            <div
                css={css`
                    color: #000000;
                    display: flex;
                    align-items: center;
                    height: 80px;
                    position: absolute;
                    top: 16px;
                    left: 16px;
                    z-index: 100;
                `}
            >
                <img
                    src={iterateLogo}
                    alt="Iterate"
                    css={css`
                        height: 80px;
                    `}
                />
                <span
                    css={css`
                        font-weight: bold;
                        margin-left: 16px;
                        font-size: 32px;
                    `}
                >
                    RIP 🪦 G17
                </span>
            </div>
            <div
                css={css`
                    position: relative;
                    overflow: hidden;
                    width: 100%;
                    height: 100%;
                `}
            >
                <WordWrapper />
            </div>
            <GoogleSignInButton onClick={showLoginModal}>
                <img src={googleG} alt="G" /> SIGN IN WITH GOOGLE
            </GoogleSignInButton>
        </Wrapper>
    );
};

const WordWrapper = () => {
    const selectedWords = randomWords();

    return (
        <>
            {selectedWords.map(word => (
                <Word key={word.word} word={word} zIndex={word.zIndex} />
            ))}
        </>
    );
};

const Word: FC<{
    word: { word: string; color: string };
    zIndex: number;
}> = ({ word, zIndex }) => {
    return <WordInner word={word} zIndex={zIndex} />;
};

const WordInner: FC<{
    word: { word: string; color: string };
    zIndex: number;
}> = ({ word, zIndex }) => {
    const [pos, setPos] = useState({
        x: random(-150, 300),
        y: random(-150, 300),
    });

    useEffect(() => {
        setPos({
            x: random(-150, 300),
            y: random(-150, 300),
        });
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            setPos({
                x: random(-150, 300),
                y: random(-150, 300),
            });
        }, 6000);
        return () => clearInterval(interval);
    }, []);

    return (
        <span
            css={css`
                position: absolute;
                transform: rotate(${random(-30, 30)}deg) translate(${pos.x}%, ${pos.y}%);
                font-size: ${random(52, 78)}px;
                border-radius: 16px;
                padding: 16px 24px;
                color: #000000;
                background-color: ${word.color};
                z-index: ${zIndex};
                top: 50%;
                left: 20%;
                transition-property: all;
                transition-duration: 6s;
                transition-timing-function: linear;
            `}
        >
            {word.word}
        </span>
    );
};

function randomWords() {
    const shufflesWords = shuffle(words).slice(0, 5);
    return shufflesWords.map((item, i) => ({
        word: item,
        color: wordColors[i],
        zIndex: random(0, shufflesWords.length),
    }));
}

const wordColors = ['#00d058', '#ccccff', '#ffff00', '#ff93ce', '#ffc9ff'];
const words = ['🔥', '👩‍🚒', '👨‍🚒', '🚒', 'RIP G17', 'HUMANS', 'ITERATE', 'REBEL'];

const Wrapper = styled.div`
    width: 100vw;
    height: 100vh;
    background-color: #ffffff;
    position: relative;
    display: flex;
    justify-content: center;
`;

const GoogleSignInButton = styled.button`
    z-index: 1000;
    position: absolute;
    display: flex;
    align-items: center;
    font-weight: 'medium';
    display: flex;
    justify-content: center;
    height: 50px;
    padding: 0 18px 0 18px;
    border-radius: 2px;
    color: rgba(0, 0, 0, 0.54);
    background: #ffffff;
    font-size: 18px;
    bottom: 20vh;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.5);
    transition: box-shadow 0.1s ease-out;

    &:hover {
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.8);
    }

    img {
        width: 20px;
        margin-right: 18px;
    }
`;
