/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import { addWeeks, endOfMonth, endOfWeek, getWeek, setWeek, startOfMonth, startOfWeek } from 'date-fns';
import { FC, useEffect, useRef, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { Column, Row } from '../../components/Flex';
import { Header } from '../../components/Header';
import { Modal } from '../../components/Modal';
import { Spinner } from '../../components/Spinner';
import { useBookRandomDesk, useGetDeskAreas, useGetDeskBookings } from '../../network/useDesk';
import { useGetMyLunchBookings } from '../../network/useLunch';
import { User } from '../../network/useProfile';
import { useToastStore } from '../../utils/store/useToastStore';
import { AreaNames } from './components/AreaNames';
import { Button } from './components/Button';
import { DeskAreas } from './components/DeskAreas';
import { WeekSelector } from './components/WeekSelector';

const AMOUNT_OF_WEEK_TABS = 3;

export const Desk: FC<{
    profile?: User;
}> = ({ profile }) => {
    const theme = useTheme();
    const { setToast } = useToastStore();
    const currentWeekIndex = getWeek(new Date());
    const firstMount = useRef<boolean>(true);
    const [selectedWeek, setSelectedWeek] = useState<Date>(startOfWeek(new Date(), { weekStartsOn: 1 }));
    const [selectedWeekIndex, setSelectedWeekIndex] = useState(currentWeekIndex);
    const [showMapModal, setShowMapModal] = useState<boolean>(false);

    const startDate = startOfWeek(selectedWeek, { weekStartsOn: 1 });
    const endDate = addWeeks(endOfWeek(selectedWeek, { weekStartsOn: 1 }), 4);
    const deskAreas = useGetDeskAreas();
    const bookRandomDesk = useBookRandomDesk();
    const lunchBookings = useGetMyLunchBookings(startOfMonth(new Date(startDate)), endOfMonth(new Date(endDate)));
    const deskBookings = useGetDeskBookings({
        startDate,
        endDate,
    });
    const [activeDeskAreaId, setActiveDeskAreaId] = useState<number>(-1);

    useEffect(() => {
        if (firstMount.current && deskAreas?.data && deskAreas.data.length > 0) {
            setActiveDeskAreaId(deskAreas.data[0].id);
            firstMount.current = false;
        }
    }, [activeDeskAreaId, deskAreas]);

    useEffect(() => {
        const dayOfWeek = setWeek(new Date(), selectedWeekIndex);
        setSelectedWeek(startOfWeek(dayOfWeek, { weekStartsOn: 1 }));
    }, [currentWeekIndex, selectedWeekIndex]);

    const reserveRandomDesk = async () => {
        if (!deskAreas.data || !deskBookings.data) {
            return;
        }

        try {
            const randomDeskResponse = await bookRandomDesk.mutateAsync();
            const [place, , id] = randomDeskResponse.message.split('_');
            setToast({
                type: 'success',
                message: `Du har booket pult nr ${id} på ${place}`,
            });
        } catch (e: any) {
            setToast({
                type: 'error',
                message: e.response?.data.message || 'Her skjedde det noe feil...',
            });
        }
    };

    const activeDeskArea = deskAreas.data?.find(area => area.id === activeDeskAreaId);

    return (
        <div>
            <Header date={selectedWeek} />
            <Column
                css={css`
                    width: 100%;
                    padding: 0px 24px;
                    @media screen and (max-width: 800px) {
                        padding: 0px 6px;
                        margin-top: 100px;
                    }
                    padding-top: 100px;
                `}
            >
                <Row
                    css={css`
                        margin-bottom: 50px;
                        @media screen and (max-width: 800px) {
                            margin-bottom: 20px;
                        }
                    `}
                    horizontal="space-between"
                    vertical="center"
                >
                    <h1>Pultbooking</h1>
                    <div>
                        <Button
                            onClick={reserveRandomDesk}
                            css={css`
                                background-color: ${theme.colors.button.clickable};
                                color: ${theme.colors.text.secondary};
                                margin-right: 10px;
                            `}
                        >
                            Gi meg en plass
                        </Button>
                        <Button
                            css={css`
                                @media screen and (max-width: 800px) {
                                    display: none;
                                }
                            `}
                            onClick={() => setShowMapModal(true)}
                        >
                            Kart 🗺
                        </Button>
                    </div>
                    {showMapModal && (
                        <Modal closeModal={() => setShowMapModal(false)} width="90vw" height="80vh">
                            <div
                                css={css`
                                    max-width: 100%;
                                    max-height: 100%;
                                    overflow: auto;
                                `}
                            >
                                <img
                                    src="https://g17.app.iterate.no/etg_3.png"
                                    alt="Kart G17 3. etg"
                                    css={css`
                                        width: 1500px;
                                    `}
                                />
                            </div>
                        </Modal>
                    )}
                </Row>
                {(deskAreas.isLoading || deskBookings.isLoading || lunchBookings.isLoading) && <Spinner />}
                <div
                    css={css`
                        display: grid;
                        grid-template-columns: 200px 1fr;

                        @media screen and (max-width: 800px) {
                            grid-template-columns: 1fr;
                        }
                    `}
                >
                    {deskAreas.data && activeDeskArea && (
                        <AreaNames
                            areas={deskAreas.data}
                            activeDeskArea={activeDeskArea}
                            setActiveDeskArea={areaId => setActiveDeskAreaId(areaId)}
                        />
                    )}
                    <div>
                        <div
                            css={css`
                                margin-bottom: 40px;
                                max-width: 100vw;
                            `}
                        >
                            <WeekSelector
                                setWeekIndex={(weekIndex: number) => setSelectedWeekIndex(weekIndex)}
                                amountOfWeeksVisible={AMOUNT_OF_WEEK_TABS}
                            />
                        </div>
                        {deskAreas.data && deskBookings.data && lunchBookings.data && activeDeskArea && (
                            <DeskAreas
                                activeDeskArea={activeDeskArea}
                                lunchBookings={lunchBookings.data}
                                bookings={deskBookings.data}
                                selectedWeek={selectedWeek}
                                profile={profile}
                            />
                        )}
                    </div>
                </div>
            </Column>
        </div>
    );
};
