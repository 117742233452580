/** @jsxImportSource @emotion/react */
import { css, keyframes, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { add, getHours, getMinutes, startOfDay } from 'date-fns';
import { FC, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Modal } from '../../../components/Modal';
import { useCalendarById } from '../../../network/useCalendar';
import { formatLocale } from '../../../utils/date';
import { validEmail } from '../../../utils/test';
import { NewEvent, UpdateEvent } from '../Calendar';

const convertDateTimeToSelectorTime = (date: Date): number => getHours(date) * 60 + getMinutes(date);

export const BookingModal: FC<BookingModalType> = ({
    start,
    end,
    setStart,
    setEnd,
    saveEvent,
    rooms,
    date,
    closeModal,
    userEmail,
    refetchCalendar,
    eventId,
    deleteEvent,
}) => {
    const theme = useTheme();
    const { data } = useCalendarById(eventId);
    const [title, setTitle] = useState<string | undefined>();
    const [room, setRoom] = useState<string | undefined>();
    const [eventStart, setEventStart] = useState<number | null>(start);
    const [eventEnd, setEventEnd] = useState<number | null>(end);
    const [error, setError] = useState('');
    const [guest, setGuest] = useState('');
    const [guests, setGuests] = useState<string[]>([userEmail]);
    const [description, setDescription] = useState<string>('');
    const [isSaving, setIsSaving] = useState(false);

    const canDeleteEvent = !!data && data.attendees?.length ? data.attendees[0].email === userEmail : false;

    const handleAddGuest = () => {
        if (!validEmail(guest)) {
            setError('Not valid email');
        } else {
            if (!guests.includes(guest)) {
                setGuests([...guests, guest]);
                setGuest('');
            }
        }
    };

    useEffect(() => {
        if (eventId && data?.id) {
            setTitle(data.title);
            setRoom(data.room);
            setDescription(data.description ?? '');
            setEventStart(convertDateTimeToSelectorTime(new Date(data.start)));
            setEventEnd(convertDateTimeToSelectorTime(new Date(data.end)));
            setGuests(data.attendees?.filter(attendee => !attendee.resource).map(attendee => attendee.email) ?? []);
        }
    }, [data, end, eventId, start]);

    const handleRemoveGuest = (removeGuest: string) => {
        setGuests(arr => arr.filter(g => g !== removeGuest));
    };

    const handleSubmit = async () => {
        if (!eventStart || !eventEnd || !title || !room) {
            setError('Title, room, start time and end time must be set');
            return;
        }
        setIsSaving(true);
        setError('');

        let requestBody: NewEvent | UpdateEvent = {
            title,
            room,
            start: formatLocale(add(date, { minutes: eventStart }), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"),
            end: formatLocale(add(date, { minutes: eventEnd }), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"),
            guests,
            description,
        };
        if (data?.id) {
            const roomResource = data.attendees!.find(attendee => attendee.resource);
            requestBody = {
                ...requestBody,
                id: data.id,
                roomResource,
            };
        }

        await saveEvent({
            ...requestBody,
        });

        refetchCalendar();
        closeModal();
    };

    return (
        <Modal closeModal={closeModal}>
            <Form>
                <TitleInput
                    autoFocus
                    type="text"
                    placeholder="Add title"
                    value={title}
                    onChange={e => setTitle(e.target.value)}
                    error={Boolean(error && !title)}
                />

                <ChooseRoom>
                    <Select
                        value={room}
                        onChange={event => setRoom(event.target.value)}
                        error={Boolean(error && !room)}
                    >
                        <option value={undefined} disabled={room !== undefined}>
                            Choose room
                        </option>
                        {rooms.map(room => (
                            <option key={room.id} value={room.id}>
                                {room.name}
                            </option>
                        ))}
                    </Select>
                    <a href="https://g17.app.iterate.no/" target="_blank" rel="noopener noreferrer">
                        Map &#10697;
                    </a>
                </ChooseRoom>
                <SelectTime>
                    <DatePickerWrapper error={!eventStart}>
                        <DatePicker
                            selected={add(startOfDay(new Date()), {
                                minutes: eventStart ? eventStart : 0,
                            })}
                            onChange={(newDate: Date) => {
                                setStart(getHours(newDate) * 60 + getMinutes(newDate));
                                setEventStart(getHours(newDate) * 60 + getMinutes(newDate));
                            }}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="HH:mm"
                            timeFormat="HH:mm"
                        />
                    </DatePickerWrapper>
                    <span style={{ margin: '0 8px 0 8px' }}>–</span>
                    <DatePickerWrapper error={!eventEnd}>
                        <DatePicker
                            selected={add(startOfDay(new Date()), {
                                minutes: eventEnd ? eventEnd : 0,
                            })}
                            onChange={(newDate: Date) => {
                                setEnd(getHours(newDate) * 60 + getMinutes(newDate));
                                setEventEnd(getHours(newDate) * 60 + getMinutes(newDate));
                            }}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="HH:mm"
                            timeFormat="HH:mm"
                        />
                    </DatePickerWrapper>
                    &nbsp;
                    <span style={{ marginLeft: 8 }}>on {formatLocale(date, 'd LLL yyyy')}</span>
                </SelectTime>
                <div style={{ height: 25 }} />
                <AddGuest>
                    <input
                        type="text"
                        placeholder="Add guests"
                        value={guest}
                        onChange={event => setGuest(event.target.value)}
                        onKeyDown={event => {
                            if (event.key === 'Enter') {
                                handleAddGuest();
                            }
                        }}
                    />
                    <button onClick={() => handleAddGuest()} />
                </AddGuest>
                <Guests>
                    {guests.map(g => (
                        <div key={g}>
                            <span>
                                {g}
                                {g === userEmail && ' (initiator)'}
                            </span>
                            {g !== userEmail && <button onClick={() => handleRemoveGuest(g)} />}
                        </div>
                    ))}
                </Guests>
                <div style={{ height: 5 }} />
                <TextArea
                    css={css`
                        border: 1px solid lightgray;
                        border-radius: 4px;
                        padding: 10px;
                        max-width: 100%;
                    `}
                    value={description}
                    placeholder="Add description"
                    onChange={event => setDescription(event.target.value)}
                />
            </Form>
            <div style={{ height: 25 }} />

            {error && <span style={{ color: 'red' }}>{error}</span>}
            <ButtonWrapper>
                <Button onClick={() => closeModal()}>Cancel</Button>
                <div
                    css={css`
                        display: flex;
                    `}
                >
                    {canDeleteEvent && data?.id && (
                        <Button
                            backgroundColor={theme.colors.button.danger}
                            color="#ffffff"
                            onClick={() => {
                                closeModal();
                                deleteEvent(data?.id);
                            }}
                        >
                            Delete
                        </Button>
                    )}
                    <Button
                        backgroundColor="#52e7be"
                        color="#ffffff"
                        css={css`
                            margin-left: 8px;
                        `}
                        disabled={isSaving}
                        onClick={handleSubmit}
                    >
                        Save
                        {isSaving && (
                            <span
                                css={css`
                                    width: 16px;
                                    height: 16px;
                                    position: relative;
                                    margin-left: 8px;
                                `}
                            >
                                <span
                                    css={css`
                                        width: 16px;
                                        height: 16px;
                                        border-radius: 8px;
                                        border: 3px solid;
                                        position: absolute;
                                        border-color: #ffffff;
                                        border-top-color: #52e7be;
                                        top: 0;
                                        left: 0;
                                        animation-name: ${keyframes`
                                        from {
                                                transform:rotate(0deg);
                                            }
                                            to {
                                                transform:rotate(360deg);
                                            }
                                        `};
                                        animation-duration: 1500ms;
                                        animation-iteration-count: infinite;
                                        animation-timing-function: linear;
                                    `}
                                />
                            </span>
                        )}
                    </Button>
                </div>
            </ButtonWrapper>
        </Modal>
    );
};

type BookingModalType = {
    start: number | null;
    end: number | null;
    setStart: (s: number) => void;
    setEnd: (s: number) => void;
    saveEvent: (newEvent: NewEvent) => void;
    rooms: Array<{ id: string; name: string }>;
    date: Date;
    closeModal: () => void;
    refetchCalendar: () => void;
    userEmail: string;
    deleteEvent: (id: string) => void;
    eventId?: string;
};

const Form = styled.div`
    display: flex;
    flex-direction: column;
`;

const TitleInput = styled.input<{ error: boolean }>`
    font-size: 28px;
    border: none;
    border-bottom: 1px solid #e2e2e7;
    outline-style: none;
    ${props =>
        props.error && {
            outlineStyle: 'auto',
            outlineColor: 'red',
        }}
`;

const TextArea = styled.textarea<{ error?: boolean }>`
    font-size: 16px;
    border: none;
    border-bottom: 1px solid #e2e2e7;
    outline-style: none;
    ${props =>
        props.error && {
            outlineStyle: 'auto',
            outlineColor: 'red',
        }}
`;

const Select = styled.select<{ error: boolean }>`
    height: 32px;
    border: none;
    font-size: 16px;
    background-color: #e2e2e7;
    border-radius: 4px;
    ${props =>
        props.error && {
            outlineStyle: 'auto',
            outlineColor: 'red',
        }}
`;

const SelectTime = styled.div`
    display: flex;
    align-items: center;
`;

const ChooseRoom = styled.div`
    margin: 16px 0;
    display: grid;
    grid-gap: 16px;
    grid-template-columns: 1fr auto;
    align-items: center;

    a:hover {
        text-decoration: underline;
    }
`;

const AddGuest = styled.div`
    display: flex;

    input {
        height: 32px;
        font-size: 16px;
        border: none;
        border-bottom: 1px solid #e2e2e7;
        outline-style: none;
        flex: 1;
    }

    button {
        height: 32px;
        width: 32px;
        border: 1px solid #e2e2e7;
        border-radius: 16px;
        position: relative;
        margin-left: 8px;

        ::before {
            content: '';
            position: absolute;
            top: 14px;
            left: 7px;
            height: 3px;
            width: 17px;
            background-color: black;
        }
        ::after {
            content: '';
            position: absolute;
            top: 7px;
            left: 14px;
            height: 17px;
            width: 3px;
            background-color: black;
        }
    }
`;

const Guests = styled.div`
    display: flex;
    flex-direction: column;

    div {
        display: flex;
        flex-direction: row;
        margin: 2px 0 6px 0;

        :first-of-type {
            margin-top: 10px;
        }
        span {
            flex: 1;
        }

        button {
            height: 32px;
            width: 32px;
            border: 1px solid #e2e2e7;
            border-radius: 16px;
            position: relative;
            margin-left: 8px;

            ::before {
                content: '';
                position: absolute;
                top: 14px;
                left: 7px;
                height: 3px;
                width: 17px;
                background-color: black;
            }
        }
    }
`;

const DatePickerWrapper = styled.div<{ error?: boolean }>`
    input {
        height: 32px;
        width: 60px;
        font-size: 16px;
        text-align: center;
        border: none;
        border-bottom: 1px solid #e2e2e7;
        outline-style: none;
        ${props =>
            props.error && {
                outlineStyle: 'auto',
                outlineColor: 'red',
            }}
    }
`;

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: space-between;
`;

const Button = styled.button<{ backgroundColor?: string; color?: string }>`
    height: 32px;
    font-size: 16px;
    border: none;
    border-radius: 4px;
    color: ${props => props.color || '#000000'};
    background-color: ${props => props.backgroundColor || '#e2e2e7'};
    padding: 0 8px 0 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;

    &:disabled {
        opacity: 0.6;
        cursor: no-drop;
    }
`;
