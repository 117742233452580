/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { differenceInMinutes, isBefore } from 'date-fns';
import { readableColor, transparentize } from 'polished';
import { FC } from 'react';
import { animated, useSpring } from 'react-spring';
import { Event as EventType } from '../../../../network/useCalendar';
import { darkColors, lightColors } from '../../../../themes/theme';
import { formatLocale } from '../../../../utils/date';

export const Event: FC<{
    day: Date;
    event: EventType;
    columns: number;
    column: number;
    setActiveEvent: (event: EventType | null) => void;
}> = ({ day, event, column, columns, setActiveEvent }) => {
    const isInThePast = isBefore(event.end, new Date());
    const left = (100 / columns) * column;
    const width = 100 / columns;
    const height = differenceInMinutes(event.end, event.start);
    const top = differenceInMinutes(event.start, day);
    const backgroundColor = transparentize(isInThePast ? 0.5 : 0, event.color);
    const [props, set] = useSpring(() => ({
        width: `calc(${100 / columns}% - 1px)`,
        left: `${left > 100 - width ? 100 - width : left}%`,
        zIndex: 1,
        immediate: (key: string) => (key === 'zIndex' ? true : false),
    }));

    return (
        <animated.button
            id="calendar-event"
            title={event.creator.displayName || event.creator.email}
            onMouseEnter={() => set({ width: 'calc(100% - 1px)', left: '0', zIndex: 10 })}
            onMouseLeave={() =>
                set({
                    width: `calc(${100 / columns}% - 1px)`,
                    left: `${left > 100 - width ? 100 - width : left}%`,
                    zIndex: 1,
                })
            }
            onClick={e => {
                e.stopPropagation();
                setActiveEvent(event);
            }}
            style={props}
            css={css`
                position: absolute;
                left: 0;
                right: 0;
                top: ${top + 1}px;
                height: ${height < 30 ? 29 : height - 1}px;
                background-color: ${backgroundColor};
                color: white;
                padding: 8px;
                border: 1px solid ${event.color};
                border-radius: 4px;
                overflow: hidden;
                opacity: ${isInThePast ? 0.6 : 1};
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                text-align: left;
            `}
        >
            <div
                id="calendar-event"
                css={css`
                    color: ${readableColor(backgroundColor, lightColors.text.primary, darkColors.text.primary)};
                `}
            >
                {formatTime(event.start)} - {formatTime(event.end)}
            </div>
            <animated.div
                id="calendar-event"
                css={css`
                    overflow: hidden;
                    text-overflow: ellipsis;
                    color: ${readableColor(backgroundColor, lightColors.text.primary, darkColors.text.primary)};
                `}
                style={{
                    width: props.width,
                }}
            >
                ({event.creator.displayName || event.creator.email})
            </animated.div>
            <div
                id="calendar-event"
                css={css`
                    color: ${readableColor(backgroundColor, lightColors.text.primary, darkColors.text.primary)};
                `}
            >
                {prettifyName(event.room)}
            </div>
        </animated.button>
    );
};
function prettifyName(name: string) {
    let match = name.match(/^G17-\d-(.*) \((\d*)\)/);
    if (match) {
        return match[1];
    }
    return name;
}
function formatTime(d: Date) {
    return formatLocale(d, 'HH:mm');
}
