/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import { addWeeks, endOfWeek, getWeek, setWeek } from 'date-fns';
import startOfWeek from 'date-fns/startOfWeek';
import { FC, Fragment, useEffect, useRef, useState } from 'react';
import { Column, Row } from '../../components/Flex';
import { Header } from '../../components/Header';
import { Delete } from '../../components/icons/Delete';
import { Modal } from '../../components/Modal';
import { AdminRoom, useGetAllRooms, useGetAllUsers, useReserveAdminDesk } from '../../network/useAdmin';
import { Booking, useDeleteDeskReservation, useGetAllBookings } from '../../network/useDesk';
import { User } from '../../network/useProfile';
import { formatLocale } from '../../utils/date';
import { Button } from '../desk/components/Button';
import { WeekSelector } from '../desk/components/WeekSelector';

const AMOUNT_OF_WEEK_TABS = 4;

export const AllDeskBookings: FC = () => {
    const currentWeekIndex = getWeek(new Date());
    const theme = useTheme();
    const [selectedWeek, setSelectedWeek] = useState<Date>(startOfWeek(new Date(), { weekStartsOn: 1 }));
    const [selectedWeekIndex, setSelectedWeekIndex] = useState(currentWeekIndex);
    const [startDate, setStartDate] = useState<Date>(startOfWeek(selectedWeek, { weekStartsOn: 1 }));
    const [endDate, setEndDate] = useState<Date>(addWeeks(endOfWeek(selectedWeek, { weekStartsOn: 1 }), 4));
    const [name, setName] = useState<string>('');
    const [showAddBookingModal, setShowAddBookingModal] = useState(false);

    const { data, error, refetch } = useGetAllBookings(startDate, endDate, name);
    useEffect(() => {
        const dayOfWeek = setWeek(new Date(), selectedWeekIndex);
        setStartDate(startOfWeek(dayOfWeek, { weekStartsOn: 1 }));
        setEndDate(endOfWeek(dayOfWeek, { weekStartsOn: 1 }));
        setSelectedWeek(startOfWeek(dayOfWeek, { weekStartsOn: 1 }));
    }, [currentWeekIndex, selectedWeekIndex]);

    if (error) {
        return (
            <div>
                <Header date={selectedWeek} />
                <Column
                    css={css`
                        padding: 0 24px 0 24px;
                    `}
                >
                    {error.response?.data.message}
                </Column>
            </div>
        );
    }

    return (
        <div>
            <Header date={selectedWeek} />
            <Column
                css={css`
                    padding: 0 24px 0 24px;
                `}
            >
                <Row>
                    <h1>Alle bookinger</h1>
                </Row>
                <Row
                    css={css`
                        margin-bottom: 48px;
                        overflow-x: auto;
                        button:not(:last-child) {
                            margin-right: 16px;
                        }
                        @media screen and (max-width: 800px) {
                            margin-bottom: 12px;
                        }
                    `}
                >
                    <WeekSelector
                        amountOfWeeksVisible={AMOUNT_OF_WEEK_TABS}
                        setWeekIndex={(weekIndex: number) => setSelectedWeekIndex(weekIndex)}
                    />
                </Row>
                <Row
                    css={css`
                        margin-top: 25px;
                    `}
                >
                    <input
                        css={css`
                            height: 50px;
                            width: 50%;
                        `}
                        type="text"
                        value={name}
                        onChange={({ target }) => setName(target.value)}
                        placeholder="Navn (minst 2 bokstaver)"
                    />
                </Row>
                <Row
                    css={css`
                        margin-top: 24px;
                    `}
                >
                    <Button onClick={() => setShowAddBookingModal(true)}>Legg til booking</Button>
                </Row>
                <table
                    css={css`
                        margin-top: 32px;
                        margin-bottom: 64px;
                        border-collapse: collapse;

                        th,
                        td {
                            padding-left: 4px;
                            text-align: left;
                        }

                        tbody {
                            tr {
                                &:hover {
                                    td {
                                        background-color: ${theme.colors.background.secondary};
                                    }
                                }
                                td {
                                    padding-top: 8px;
                                    padding-bottom: 8px;
                                }
                            }
                        }
                    `}
                >
                    <thead>
                        <tr>
                            <th />
                            <th>Navn</th>
                            <th />
                        </tr>
                    </thead>
                    <tbody>
                        {data?.map(booking => (
                            <BookingRow key={booking.bookingId} booking={booking} />
                        ))}
                    </tbody>
                </table>
            </Column>
            {showAddBookingModal && (
                <AddBookingModal closeModal={() => setShowAddBookingModal(false)} refetch={refetch} />
            )}
        </div>
    );
};

const BookingRow: FC<{
    booking: Booking;
}> = ({ booking }) => {
    const deleteDeskReservation = useDeleteDeskReservation();
    const handleDeleteDeskReservation = () => {
        if (booking) {
            deleteDeskReservation.mutateAsync({
                bookingId: booking.bookingId,
            });
        }
    };

    return (
        <tr>
            <td
                css={css`
                    text-align: left;
                    max-width: 80px !important;
                `}
            >
                <img
                    src={booking.picture}
                    alt={booking.name}
                    css={css`
                        width: 80px;
                        height: 80px;
                        border-radius: 20px;
                    `}
                />
            </td>
            <td>{booking.name}</td>
            <td
                css={css`
                    text-align: right !important;
                `}
            >
                <button onClick={handleDeleteDeskReservation}>
                    <Delete />
                </button>
            </td>
        </tr>
    );
};

const AddBookingModal: FC<{
    closeModal: () => void;
    refetch: () => void;
}> = ({ closeModal, refetch }) => {
    const theme = useTheme();
    const [user, setUser] = useState<User | null>(null);
    const [deskId, setDeskId] = useState<number | null>(null);
    const [note, setNote] = useState<string | undefined>(undefined);
    const [startDate, setStartDate] = useState<Date>(new Date());
    const [endDate, setEndDate] = useState<Date>(new Date());
    const { data: users, isLoading: usersIsLoading } = useGetAllUsers();
    const { data: rooms, isLoading: roomsIsLoading } = useGetAllRooms();
    const { mutateAsync: addBooking } = useReserveAdminDesk();

    return (
        <Modal closeModal={closeModal}>
            <h2>Legg til booking</h2>
            <InputWithSelectDropdown
                loading={usersIsLoading}
                data={users}
                filterKey="name"
                selectedData={user?.name}
                setData={setUser}
            />
            <div
                css={css`
                    height: 16px;
                `}
            />
            <InputWithSelectDropdownRooms loading={roomsIsLoading} rooms={rooms} setDesk={setDeskId} />
            <div
                css={css`
                    height: 16px;
                `}
            />
            <input
                placeholder="Notat"
                css={[
                    css`
                        border: 1px solid ${theme.colors.border.primary};
                        padding: 8px;
                        border-radius: 4px;
                        width: 100%;
                    `,
                ]}
                onChange={e => setNote(e.target.value)}
            />
            <div
                css={css`
                    height: 16px;
                `}
            />
            <input
                value={formatLocale(startDate, 'yyyy-MM-dd')}
                onChange={e => setStartDate(new Date(e.target.value))}
                type="date"
                css={[
                    css`
                        border: 1px solid ${theme.colors.border.primary};
                        padding: 8px;
                        border-radius: 4px;
                        width: 100%;
                    `,
                ]}
                placeholder="Fra data"
            />
            <div
                css={css`
                    height: 16px;
                `}
            />
            <input
                value={formatLocale(endDate, 'yyyy-MM-dd')}
                onChange={e => setEndDate(new Date(e.target.value))}
                type="date"
                css={[
                    css`
                        border: 1px solid ${theme.colors.border.primary};
                        padding: 8px;
                        border-radius: 4px;
                        width: 100%;
                    `,
                ]}
                placeholder="Til data"
            />
            <div
                css={css`
                    height: 32px;
                `}
            />
            <Button
                disabled={!deskId && !user}
                onClick={async () => {
                    await addBooking({
                        deskId: deskId!,
                        userSub: user!.sub,
                        note: note ?? undefined,
                        startDate,
                        endDate,
                    });
                    refetch();
                    closeModal();
                }}
            >
                Legg til booking
            </Button>
        </Modal>
    );
};

const InputWithSelectDropdown: FC<{
    loading: boolean;
    selectedData?: string;
    data?: Record<string, any>[];
    filterKey: string;
    setData: (data: any) => void;
}> = ({ loading, data, selectedData, setData, filterKey }) => {
    const theme = useTheme();
    const [value, setName] = useState<string>(selectedData ?? '');
    const [showUsersDropdown, setShowUsersDropdown] = useState(false);
    const ref = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
        if (selectedData) {
            setName(selectedData);
        }
    }, [selectedData]);

    return (
        <div>
            <input
                value={value}
                onChange={e => setName(e.target.value)}
                onFocus={() => setShowUsersDropdown(true)}
                onBlur={() => {
                    setTimeout(() => {
                        setShowUsersDropdown(false);
                    }, 100);
                }}
                css={[
                    css`
                        border: 1px solid ${theme.colors.border.primary};
                        padding: 8px;
                        border-radius: 4px;
                        width: 100%;
                    `,
                    showUsersDropdown &&
                        css`
                            border-bottom-left-radius: 0;
                            border-bottom-right-radius: 0;
                        `,
                ]}
                type="text"
                placeholder="Skriv inn navnet"
            />
            {showUsersDropdown && (
                <div
                    css={css`
                        z-index: 10000;
                        top: 30px;
                        width: 100%;
                        background: ${theme.colors.background.primary};
                        border: 1px solid ${theme.colors.border.primary};
                        border-bottom-left-radius: 4px;
                        border-bottom-right-radius: 4px;
                        overflow: hidden;
                    `}
                >
                    {loading ? (
                        <p>Laster inn brukere...</p>
                    ) : (
                        data
                            ?.filter(d =>
                                value.length > 1
                                    ? d[filterKey].toLocaleLowerCase().includes(value.toLocaleLowerCase())
                                    : true
                            )
                            .map(d => (
                                <button
                                    key={d[filterKey]}
                                    onClick={() => {
                                        setData(d);
                                        ref.current?.blur();
                                    }}
                                    css={css`
                                        width: 100%;

                                        display: flex;
                                        padding: 8px;
                                        &:hover {
                                            background: ${theme.colors.background.secondary};
                                        }
                                    `}
                                >
                                    {d[filterKey]}
                                </button>
                            ))
                    )}
                </div>
            )}
        </div>
    );
};

const InputWithSelectDropdownRooms: FC<{
    loading: boolean;
    rooms?: AdminRoom[];
    selectedDesk?: string;
    setDesk: (deskId: number) => void;
}> = ({ rooms, selectedDesk, setDesk }) => {
    const theme = useTheme();
    const [showUsersDropdown, setShowUsersDropdown] = useState(false);

    return (
        <div>
            <select
                value={selectedDesk}
                onChange={e => {
                    setDesk(parseInt(e.target.value));
                }}
                onFocus={() => setShowUsersDropdown(true)}
                onBlur={() => setShowUsersDropdown(false)}
                css={[
                    css`
                        border: 1px solid ${theme.colors.border.primary};
                        padding: 8px;
                        border-radius: 4px;
                        width: 100%;
                    `,
                    showUsersDropdown &&
                        css`
                            border-bottom-left-radius: 0;
                            border-bottom-right-radius: 0;
                        `,
                ]}
                placeholder="Skriv inn pult"
            >
                <option value="">Velg pult</option>
                {rooms?.map(room =>
                    room.desks.map(desk => (
                        <option
                            key={desk.id}
                            value={desk.id}
                            css={css`
                                display: flex;
                                width: 100%;
                                padding: 8px;
                                &:hover {
                                    background: ${theme.colors.background.secondary};
                                }
                            `}
                        >
                            {room.name} – {desk.name}
                        </option>
                    ))
                )}
            </select>
        </div>
    );
};
