/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import { FC } from 'react';
import { Header } from '../../components/Header';
import { useProfile } from '../../network/useProfile';

export const Profile: FC = () => {
    const date = new Date();
    const theme = useTheme();
    const { data } = useProfile();

    return (
        <div
            css={css`
                max-width: 800px;
                margin: 0px auto;
                margin-top: 100px;
                padding: 0px 20px;

                @media screen and (max-width: ${theme.screenSize.mobile}px) {
                    margin-top: 100px;
                }
            `}
        >
            <Header date={date} />
            <h1>Profil</h1>
            <div>
                <h2>Navn</h2>
                <p>{data?.name}</p>
                <h2>Selskap</h2>
            </div>
        </div>
    );
};
