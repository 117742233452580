/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { FC } from 'react';
import { Column, Row } from '../../components/Flex';
import { Event } from '../../network/useCalendar';
import { formatLocale } from '../../utils/date';

export const UpcomingEvents: FC<{ events: Event[] }> = ({ events }) => {
    if (events.length === 0) {
        return null;
    }

    return (
        <Column
            flex={1}
            vertical="flex-end"
            css={css`
                color: white;
                font-size: 20px;
            `}
        >
            <span>Neste booking:</span>
            <Row
                css={css`
                    max-width: calc(100vw - 32px);
                    flex-wrap: nowrap;
                    overflow-x: auto;
                    padding-bottom: 16px;
                `}
            >
                {events.map(event => (
                    <span
                        key={event.id}
                        css={css`
                            white-space: nowrap;
                            margin-right: 20px;
                        `}
                    >
                        {formatLocale(event.start, 'HH:mm')} - {formatLocale(event.end, 'HH:mm')}
                    </span>
                ))}
            </Row>
        </Column>
    );
};
