/** @jsxImportSource @emotion/react */
import { Global, css, useTheme } from '@emotion/react';
import { normalize } from 'polished';
import { FC } from 'react';

export const GlobalStyle: FC = () => {
    const theme = useTheme();

    return (
        <Global
            styles={css`
                ${normalize()};
                html {
                    box-sizing: border-box;
                }
                *,
                *:before,
                *:after {
                    box-sizing: inherit;
                }
                body {
                    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
                        'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
                    font-size: 16px;
                    margin: 0;
                    padding: 0;
                    background-color: ${theme.colors.background.primary};
                    color: ${theme.colors.text.primary};
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                }

                button {
                    font: inherit;
                    color: inherit;
                    background: none;
                    cursor: pointer;
                    border: none;
                    padding: 0;
                }
                button:disabled {
                    cursor: default;
                }

                a {
                    margin: 0;
                    text-decoration: none;
                    color: inherit;
                }
            `}
        />
    );
};
