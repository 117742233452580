/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { startOfWeek } from 'date-fns';
import React, { FC } from 'react';
import { Header } from './Header';

const MESSAGE = 'This is not the page you are looking for';

export const PageNotFound: FC<{ message?: string }> = ({ message = MESSAGE }) => {
    const currentWeek = startOfWeek(new Date(), { weekStartsOn: 1 });
    return (
        <div>
            <Header date={currentWeek} />
            <div
                css={css`
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    height: 80vh;
                `}
            >
                <img
                    css={css`
                        height: 200px;
                        width: 200px;
                    `}
                    alt="Iterate snake"
                    src="https://uploads-ssl.webflow.com/5ea18b09bf3bfd55814199f9/5ea18b09bf3bfda137419a00_petri_square_03.gif"
                />
                <p>{message}</p>
            </div>
        </div>
    );
};
