import { meetApi } from './api';
import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { add } from 'date-fns';

type Creator = {
    email: string;
    displayName?: string;
};

export type CalendarRes = {
    id: string;
    name: string;
    events: Event[];
};

export type Event = {
    id: string;
    start: Date;
    end: Date;
    room: string;
    color: string;
    creator: Creator;
    title?: string;
    description?: string;
    attendees?: {
        displayName: string;
        email: string;
        resource: boolean;
        responseStatus: string;
    }[];
};

export const useCalendar = (date: Date) => {
    return useQuery<CalendarRes[], AxiosError>(
        ['calendar', date],
        () =>
            meetApi
                .get<CalendarRes[]>('api/v1/calendar', {
                    params: {
                        startDate: date.toISOString(),
                        endDate: add(date, { weeks: 1 }).toISOString(),
                    },
                })
                .then(res =>
                    res.data.map(data => ({
                        ...data,
                        events: data.events.map(event => ({
                            ...event,
                            start: new Date(event.start),
                            end: new Date(event.end),
                        })),
                    }))
                ),
        {
            keepPreviousData: true,
        }
    );
};

export const useCalendarById = (id?: string) => {
    return useQuery<Event, AxiosError>(
        ['calendarEventById', id],
        async () => {
            const res = await meetApi.get<Event>('api/v1/calendar/event', {
                params: {
                    id,
                },
            });

            return res.data;
        },
        {
            enabled: !!id,
            retry: false,
        }
    );
};
