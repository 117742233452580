/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { addMinutes } from 'date-fns';
import { FC } from 'react';
import { Column } from '../../components/Flex';
import { Event } from '../../network/useCalendar';
import { formatLocale } from '../../utils/date';
import { availableUntilText, takenUntilText } from './utils';

export const Status: FC<{
    timeUntilNextEvent: number;
    timeLeftCurrentEvent: number;
    upcomingEvents: Event[];
}> = ({ timeUntilNextEvent, timeLeftCurrentEvent, upcomingEvents }) => {
    const isInUse = timeLeftCurrentEvent > -1;

    return (
        <Column
            flex={4}
            vertical="center"
            css={css`
                font-size: 80px;
                color: white;
            `}
        >
            <span>{isInUse ? 'I BRUK' : 'LEDIG'}</span>
            <span
                css={css`
                    opacity: 0.8;
                `}
            >
                {isInUse
                    ? takenUntilText(timeLeftCurrentEvent)
                    : availableUntilText(timeUntilNextEvent, upcomingEvents)}
            </span>
            {isInUse && (
                <span
                    css={css`
                        font-size: 20px;
                    `}
                >
                    Ledig klokken {formatLocale(addMinutes(new Date(), timeLeftCurrentEvent), 'HH:mm')}
                </span>
            )}
        </Column>
    );
};
