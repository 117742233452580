/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import { FC, useState } from 'react';
import { Modal } from '../../components/Modal';
import { useAddCompanyName, User } from '../../network/useProfile';
import { Button } from '../desk/components/Button';

export const AddCompanyNameModal: FC<{ profile: User }> = ({ profile }) => {
    const theme = useTheme();
    const addCompanyName = useAddCompanyName();
    const [modalVisible, setModalVisible] = useState(!profile.company);
    const [companyName, setCompanyName] = useState(profile.company || '');

    const handleAddCompanyNameClick = async () => {
        await addCompanyName.mutateAsync(companyName);
        setModalVisible(false);
    };

    return modalVisible ? (
        <Modal closeModal={() => setModalVisible(false)}>
            <h2>Hvor jobber du?</h2>
            <span>Dette er så vi kan sende faktura for lunsj</span>
            <div
                css={css`
                    height: 32px;
                `}
            />
            <div
                css={css`
                    display: flex;
                    justify-content: flex-start;
                    flex-wrap: wrap;
                    button {
                        white-space: nowrap;
                        margin-bottom: 16px;
                        margin-right: 16px;
                    }
                `}
            >
                <Button onClick={() => setCompanyName('Iterate')}>Iterate</Button>
                <Button onClick={() => setCompanyName('Vake')}>Vake</Button>
                <Button onClick={() => setCompanyName('Flow technologies')}>Flow technologies</Button>
                <Button onClick={() => setCompanyName('Woolit')}>Woolit</Button>
                <Button onClick={() => setCompanyName('Neddy')}>Neddy</Button>
            </div>
            <input
                value={companyName}
                onChange={e => setCompanyName(e.target.value)}
                css={css`
                    border: 1px solid ${theme.colors.border.primary};
                    padding: 8px;
                    border-radius: 4px;
                `}
                type="text"
                placeholder="Skriv inn bedriftsnavn"
            />
            <div
                css={css`
                    height: 32px;
                `}
            />
            <Button onClick={handleAddCompanyNameClick}>Lagre</Button>
        </Modal>
    ) : null;
};
