/** @jsxImportSource @emotion/react */
import { FC } from 'react';
import { css } from '@emotion/react';

export const Arrow: FC<{
    size?: number;
    alt?: string;
    direction?: 'UP' | 'DOWN' | 'LEFT' | 'RIGHT';
}> = ({ size, alt, direction }) => (
    <svg
        width={size ? size * 1.714285714 : 14}
        height={size ? size : 24}
        viewBox="0 0 14 24"
        version="1.1"
        css={[
            css`
                fill-rule: evenodd;
                clip-rule: evenodd;
                stroke-linejoin: round;
                stroke-miterlimit: 2;
            `,
            direction &&
                css`
                    transform: rotate(${directionToDeg[direction]}deg);
                `,
        ]}
    >
        {alt && <title>{alt}</title>}
        <g transform="matrix(1,0,0,1,-5.05243,-0.239133)">
            <g transform="matrix(1.13509,0,0,1.13509,-1.62024,-1.49683)">
                <path
                    d="M6.151,12.163C6.001,12.013 6.001,11.768 6.151,11.618C7.613,10.156 14.505,3.264 15.967,1.802C16.118,1.652 16.362,1.652 16.512,1.802C16.869,2.159 17.49,2.78 17.847,3.137C17.998,3.287 17.998,3.532 17.847,3.682C16.57,4.96 11.189,10.34 9.912,11.618C9.761,11.768 9.761,12.013 9.912,12.163C11.189,13.441 16.57,18.821 17.847,20.099C17.998,20.249 17.998,20.494 17.847,20.644C17.49,21.001 16.869,21.622 16.512,21.979C16.362,22.13 16.118,22.13 15.967,21.979C14.505,20.517 7.613,13.625 6.151,12.163Z"
                    style={{ fill: 'rgb(169,178,192)' }}
                />
            </g>
        </g>
    </svg>
);

const directionToDeg: { [name: string]: number } = {
    UP: 90,
    DOWN: 270,
    LEFT: 0,
    RIGHT: 180,
};
