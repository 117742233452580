/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { addDays, isSameDay, setWeek, setYear, startOfWeek } from 'date-fns';
import React, { FC, useLayoutEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Row } from '../../components/Flex';
import { meetApi } from '../../network/api';
import { Event, useCalendar } from '../../network/useCalendar';
import { Day } from './components/day/Day';
import { CalendarHeader } from './components/CalendarHeader';
import { Hours } from './components/Hours';
import { Weekdays } from './components/Weekdays';
import { Header } from '../../components/Header';

export const Calendar: FC<{
    profile?: { email: string };
    refetchProfile: () => void;
}> = ({ profile, refetchProfile }) => {
    const { year, week } = useParams<{
        year: string;
        week: string;
    }>();
    const [room, setRoom] = useState<string>('ALL');
    const weekAsNumber = Number(week) || 1;
    const yearAsNumber = Number(year) || 1970;
    const date = startOfWeek(setWeek(setYear(new Date(1970, 1, 1), yearAsNumber), weekAsNumber), {
        weekStartsOn: 1,
    });
    const [contentScrolledLeft, setContentScrolledLeft] = useState<number>(0);
    const contentWrapperRef = useRef<HTMLDivElement | null>(null);

    useLayoutEffect(() => {
        const scroll = () => {
            const hourElement = document.getElementById('hour-7');
            const weekdayElement = document.getElementById('weekday-today');
            const dayElement = document.getElementById('day-today');

            hourElement && hourElement.scrollIntoView();
            weekdayElement && weekdayElement.scrollIntoView();
            dayElement && dayElement.scrollIntoView();
        };

        scroll();
        setTimeout(scroll, 1000);
    }, []);

    const calendarRes = useCalendar(date);

    let rooms = calendarRes?.data?.map(cal => ({ id: cal.id, name: cal.name })) || [];

    if (calendarRes.error) {
        return <div>{calendarRes.error.toString()}</div>;
    }

    if (calendarRes.isLoading) {
        return (
            <Row
                horizontal="center"
                vertical="center"
                css={css`
                    width: 100vw;
                    height: 100vh;
                `}
            >
                Loading...
            </Row>
        );
    }

    const saveEvent = async (newEvent: NewEvent) => {
        if (newEvent.id) {
            await meetApi.put('api/v1/calendar/event', newEvent);
        } else {
            await meetApi.post('api/v1/calendar/event', newEvent);
        }
        calendarRes.refetch();
    };

    const deleteEvent = async (eventId: string) => {
        await meetApi.delete(`api/v1/calendar/event/${eventId}`);
        calendarRes.refetch();
    };

    const calendars: CalendarTypeType[] =
        calendarRes.data
            ?.filter(cal => room === 'ALL' || cal.name === room)
            .map(cal => ({
                room: cal.name,
                events: cal.events.map(event => ({
                    ...event,
                    room: cal.name,
                })),
            })) ?? [];

    return (
        <Wrapper>
            <Header date={date} />
            <CalendarHeader
                date={date}
                rooms={rooms}
                changeRoom={(room: string) => setRoom(room)}
                currentRoom={room}
                refetchProfile={refetchProfile}
                isFetching={calendarRes.isFetching}
            />
            <ContentWrapper
                id="content-wrapper"
                ref={contentWrapperRef}
                onScroll={() => setContentScrolledLeft(contentWrapperRef.current?.scrollLeft ?? 0)}
            >
                <Weekdays date={date} contentScrolledLeft={contentScrolledLeft} />
                <Content>
                    <Hours date={date} />
                    <Day
                        date={addDays(date, 0)}
                        events={filterEventsForDay(calendars, addDays(date, 0))}
                        saveEvent={saveEvent}
                        deleteEvent={deleteEvent}
                        rooms={rooms}
                        userEmail={profile?.email ?? ''}
                        refetchCalendar={() => calendarRes.refetch()}
                        contentWrapperScrollTop={contentWrapperRef.current?.scrollTop || 0}
                    />
                    <Day
                        date={addDays(date, 1)}
                        events={filterEventsForDay(calendars, addDays(date, 1))}
                        saveEvent={saveEvent}
                        deleteEvent={deleteEvent}
                        rooms={rooms}
                        userEmail={profile?.email ?? ''}
                        refetchCalendar={() => calendarRes.refetch()}
                        contentWrapperScrollTop={contentWrapperRef.current?.scrollTop || 0}
                    />
                    <Day
                        date={addDays(date, 2)}
                        events={filterEventsForDay(calendars, addDays(date, 2))}
                        saveEvent={saveEvent}
                        deleteEvent={deleteEvent}
                        rooms={rooms}
                        userEmail={profile?.email ?? ''}
                        refetchCalendar={() => calendarRes.refetch()}
                        contentWrapperScrollTop={contentWrapperRef.current?.scrollTop || 0}
                    />
                    <Day
                        date={addDays(date, 3)}
                        events={filterEventsForDay(calendars, addDays(date, 3))}
                        saveEvent={saveEvent}
                        deleteEvent={deleteEvent}
                        rooms={rooms}
                        userEmail={profile?.email ?? ''}
                        refetchCalendar={() => calendarRes.refetch()}
                        contentWrapperScrollTop={contentWrapperRef.current?.scrollTop || 0}
                    />
                    <Day
                        date={addDays(date, 4)}
                        events={filterEventsForDay(calendars, addDays(date, 4))}
                        saveEvent={saveEvent}
                        deleteEvent={deleteEvent}
                        rooms={rooms}
                        userEmail={profile?.email ?? ''}
                        refetchCalendar={() => calendarRes.refetch()}
                        contentWrapperScrollTop={contentWrapperRef.current?.scrollTop || 0}
                    />
                    <Day
                        date={addDays(date, 5)}
                        events={filterEventsForDay(calendars, addDays(date, 5))}
                        saveEvent={saveEvent}
                        deleteEvent={deleteEvent}
                        rooms={rooms}
                        userEmail={profile?.email ?? ''}
                        refetchCalendar={() => calendarRes.refetch()}
                        contentWrapperScrollTop={contentWrapperRef.current?.scrollTop || 0}
                    />
                    <Day
                        date={addDays(date, 6)}
                        events={filterEventsForDay(calendars, addDays(date, 6))}
                        saveEvent={saveEvent}
                        deleteEvent={deleteEvent}
                        rooms={rooms}
                        userEmail={profile?.email ?? ''}
                        refetchCalendar={() => calendarRes.refetch()}
                        contentWrapperScrollTop={contentWrapperRef.current?.scrollTop || 0}
                    />
                </Content>
            </ContentWrapper>
        </Wrapper>
    );
};

function filterEventsForDay(calendars: CalendarTypeType[], date: Date): Event[] {
    const events = calendars
        .map(calendar => {
            return calendar.events.filter(event => isSameDay(event.start, date) || isSameDay(event.end, date));
        })
        .filter(cal => cal.length > 0)
        .flat();

    return events;
}

type CalendarTypeType = {
    room: string;
    events: Event[];
};

export type NewEvent = {
    start: string;
    end: string;
    room: string;
    title: string;
    guests: string[];
    description?: string;
    id?: string;
};

export type UpdateEvent = NewEvent & {
    roomResource: {
        resource: boolean;
        email: string;
        displayName: string;
    };
};

const Wrapper = styled.div`
    position: relative;
    display: grid;
    width: 100%;
    grid-template-rows: auto 1fr;

    @media screen and (max-width: 400px) {
        padding: 5px;
    }
`;

const ContentWrapper = styled.div`
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    user-select: none;
    margin-top: 100px;
    padding-left: 24px;

    @media screen and (max-width: 1500px) {
        overflow-x: visible;
    }

    @media screen and (max-width: ${props => props.theme.screenSize.mobile}px) {
        margin-top: 130px;
    }
`;

const Content = styled.div`
    display: flex;
    flex-direction: row;
`;
