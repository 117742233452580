/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { FC, ReactNode } from 'react';
import { NavLink } from 'react-router-dom';
import { formatLocale } from '../utils/date';
import { Menu } from './Menu';

export const Header: FC<{
    date: Date;
    children?: ReactNode;
}> = ({ date, children }) => {
    return (
        <StyledHeader>
            <div
                css={css`
                    grid-area: date;
                `}
            >
                <Title>{formatLocale(date, 'MMMM')}</Title>{' '}
                <Title style={{ fontWeight: 300 }}>{formatLocale(date, 'yyyy')}</Title>
            </div>
            <div
                css={css`
                    display: flex;
                    grid-area: navigation;
                `}
            >
                <NavLink style={({ isActive }) => (isActive ? { fontWeight: 'bold' } : {})} to="/desk">
                    Pultbooking
                </NavLink>
                <div style={{ width: 16 }} />
                <NavLink style={({ isActive }) => (isActive ? { fontWeight: 'bold' } : {})} to="/calendar">
                    Møterombooking
                </NavLink>
                {/* <div style={{ width: 16 }} />
                <NavLink style={({ isActive }) => (isActive ? { fontWeight: 'bold' } : {})} to="/lunch">
                    Lunsj
                </NavLink> */}
            </div>
            <Menu />
        </StyledHeader>
    );
};

const StyledHeader = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: ${({ theme }) => theme.colors.background.primary};
    padding: 0 24px;
    display: grid;
    align-items: center;
    grid-template-areas: 'date navigation menu';
    grid-template-columns: auto auto auto;
    grid-template-rows: auto;
    grid-gap: 24px;

    height: 70px;
    width: 100vw;
    z-index: 100;

    a {
        font-size: 28px;
    }

    @media screen and (max-width: ${({ theme }) => theme.screenSize.mobile}px) {
        padding: 16px;
        height: 100px;
        width: 100vw;
        grid-template-areas:
            'date menu'
            'navigation menu';
        grid-template-columns: auto auto;
        grid-template-rows: auto auto;

        h2,
        a {
            font-size: 17px;
        }
    }
`;

const Title = styled.h2`
    display: inline;
    font-size: 28px;
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
`;
