/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import { FC, useState } from 'react';
import { Column, Row } from '../../components/Flex';
import { Header } from '../../components/Header';
import { Modal } from '../../components/Modal';
import { Switch } from '../../components/Swich';
import { useChangeUser, useGetAllUsers } from '../../network/useAdmin';
import { User } from '../../network/useProfile';
import { Button } from '../desk/components/Button';

export const Users: FC = () => {
    const [sortBy, setSortBy] = useState('name-asc');
    const { data, isLoading } = useGetAllUsers();
    const theme = useTheme();

    return (
        <div>
            <Header date={new Date()} />
            <Column
                css={css`
                    width: 100%;
                    padding: 0px 24px;
                    @media screen and (max-width: 800px) {
                        padding: 0px 6px;
                        margin-top: 100px;
                    }
                    padding-top: 100px;
                `}
            >
                <h1>Brukere</h1>
                {isLoading && <div>Loading...</div>}
                {data && (
                    <table
                        css={css`
                            margin-top: 32px;
                            margin-bottom: 64px;
                            border-collapse: collapse;

                            th,
                            td {
                                padding-left: 4px;
                                text-align: left;
                            }

                            tbody {
                                tr {
                                    &:hover {
                                        td {
                                            background-color: ${theme.colors.background.secondary};
                                        }
                                    }
                                    td {
                                        padding-top: 8px;
                                        padding-bottom: 8px;
                                    }
                                }
                            }
                        `}
                    >
                        <thead>
                            <tr>
                                <th />
                                <th onClick={() => setSortBy(cs => (cs === 'name-asc' ? 'name-desc' : 'name-asc'))}>
                                    {`Navn ${(sortBy === 'name-asc' && '▲') || (sortBy === 'name-desc' && '▼') || ''}`}
                                </th>
                                <th
                                    onClick={() =>
                                        setSortBy(cs => (cs === 'company-asc' ? 'company-desc' : 'company-asc'))
                                    }
                                >
                                    {`Selskap ${
                                        (sortBy === 'company-asc' && '▲') || (sortBy === 'company-desc' && '▼') || ''
                                    }`}
                                </th>
                                <th>E-post</th>
                                <th
                                    onClick={() => setSortBy(cs => (cs === 'admin-asc' ? 'admin-desc' : 'admin-asc'))}
                                    css={css`
                                        text-align: center !important;
                                    `}
                                >
                                    {`Admin ${
                                        (sortBy === 'admin-asc' && '▲') || (sortBy === 'admin-desc' && '▼') || ''
                                    }`}
                                </th>
                                <th />
                            </tr>
                        </thead>
                        <tbody>
                            {data
                                .sort((a: User, b: User) => {
                                    if (sortBy === 'name-asc') {
                                        return a.name.localeCompare(b.name);
                                    } else if (sortBy === 'name-desc') {
                                        return b.name.localeCompare(a.name);
                                    } else if (sortBy === 'company-asc') {
                                        return (a.company || '').localeCompare(b.company || '');
                                    } else if (sortBy === 'company-desc') {
                                        return (b.company || '').localeCompare(a.company || '');
                                    } else if (sortBy === 'admin-asc') {
                                        return (a.admin ? 1 : 0) - (b.admin ? 1 : 0);
                                    } else if (sortBy === 'admin-desc') {
                                        return (b.admin ? 1 : 0) - (a.admin ? 1 : 0);
                                    }
                                    return 0;
                                })
                                .map(user => (
                                    <UserRow user={user} key={user.id} />
                                ))}
                        </tbody>
                    </table>
                )}
            </Column>
        </div>
    );
};

const UserRow: FC<{ user: User }> = ({ user }) => {
    const [showModal, setShowModal] = useState(false);
    const [admin, setAdmin] = useState(user.admin);
    const [companyName, setCompanyName] = useState(user.company || '');
    const changeUser = useChangeUser();
    const theme = useTheme();

    const handleChangeUser = async () => {
        const response = await changeUser.mutateAsync({
            userId: user.id,
            admin,
            company: companyName,
        });

        if (response.success) {
            setShowModal(false);
        } else {
            setCompanyName(user.company || '');
            setAdmin(user.admin);
        }
    };

    return (
        <>
            <tr>
                <td
                    css={css`
                        text-align: center !important;
                    `}
                >
                    <img
                        src={user.picture}
                        alt={user.name}
                        css={css`
                            width: 80px;
                            height: 80px;
                            border-radius: 20px;
                        `}
                    />
                </td>
                <td>{user.name}</td>
                <td>{user.company}</td>
                <td>{user.email}</td>
                <td
                    css={css`
                        text-align: center !important;
                    `}
                >
                    {user.admin ? '✅' : '❌'}
                </td>
                <td
                    css={css`
                        text-align: center !important;
                    `}
                >
                    <Button onClick={() => setShowModal(true)}>🖋</Button>
                </td>
            </tr>
            {showModal && (
                <Modal closeModal={() => setShowModal(false)}>
                    <h2>Rediger {user.name}</h2>
                    <h3>Er admin</h3>
                    <Row
                        align="center"
                        css={css`
                            margin-bottom: 16px;
                        `}
                    >
                        Admin
                        <div style={{ width: 24 }} />
                        <Switch checked={admin} onChange={() => setAdmin(!admin)} />
                    </Row>
                    <h3>Endre selskap</h3>
                    <div
                        css={css`
                            display: flex;
                            justify-content: flex-start;
                            flex-wrap: wrap;
                            button {
                                white-space: nowrap;
                                margin-bottom: 16px;
                                margin-right: 16px;
                            }
                        `}
                    >
                        <Button onClick={() => setCompanyName('Iterate')}>Iterate</Button>
                        <Button onClick={() => setCompanyName('Vake')}>Vake</Button>
                        <Button onClick={() => setCompanyName('Flow technologies')}>Flow technologies</Button>
                        <Button onClick={() => setCompanyName('Woolit')}>Woolit</Button>
                        <Button onClick={() => setCompanyName('Neddy')}>Neddy</Button>
                    </div>
                    <input
                        value={companyName}
                        onChange={e => setCompanyName(e.target.value)}
                        css={css`
                            border: 1px solid ${theme.colors.border.primary};
                            padding: 8px;
                            border-radius: 4px;
                        `}
                        type="text"
                        placeholder="Skriv inn bedriftsnavn"
                    />
                    <div
                        css={css`
                            height: 32px;
                        `}
                    />
                    <Row justify="space-between">
                        <Button onClick={() => setShowModal(false)}>Avbryt</Button>
                        <Button
                            css={css`
                                background-color: ${theme.colors.button.clickable};
                            `}
                            onClick={handleChangeUser}
                        >
                            Lagre
                        </Button>
                    </Row>
                </Modal>
            )}
        </>
    );
};
