import styled from '@emotion/styled';
import { addDays, isSameWeek, isToday, startOfWeek } from 'date-fns';
import { FC } from 'react';
import { formatLocale } from '../../../utils/date';

export const Weekdays: FC<{ date: Date; contentScrolledLeft: number }> = ({ date, contentScrolledLeft }) => {
    const firstDayOfWeek = startOfWeek(date);
    const isThisWeek = isSameWeek(new Date(), date);

    return (
        <StyledWeekdays style={{ left: -contentScrolledLeft + 24 }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>W{formatLocale(date, 'w')}</div>
            {[1, 2, 3, 4, 5, 6, 7].map(d => (
                <Day key={d} date={firstDayOfWeek} isThisWeek={isThisWeek} dayOfWeek={d} />
            ))}
        </StyledWeekdays>
    );
};

const Day: FC<{ date: Date; dayOfWeek: number; isThisWeek: boolean }> = ({ date, isThisWeek, dayOfWeek }) => {
    const isThisDay = isThisWeek && isToday(addDays(date, dayOfWeek));
    return (
        <StyledDay id={isThisDay ? 'weekday-today' : ''}>
            {formatLocale(addDays(date, dayOfWeek), 'EEEE')}
            &nbsp;
            <DateNumber isToday={isThisDay}>{formatLocale(addDays(date, dayOfWeek), 'dd')}</DateNumber>
        </StyledDay>
    );
};

const StyledWeekdays = styled.div`
    position: fixed;
    top: 100px;
    background-color: ${props => props.theme.colors.background.primary};
    z-index: 99;
    display: flex;
    flex-direction: row;
    height: 40px;
    border-color: ${props => props.theme.colors.background.secondary};
    border-style: solid;
    border-width: 0 0 1px 0;
    left: 24px;
    max-height: calc(100vh - 151px);
    overflow-y: auto;
    overflow-x: hidden;

    @media screen and (max-width: ${props => props.theme.screenSize.mobile}px) {
        height: 50px;
        top: 130px;
    }

    div:first-of-type {
        width: 60px;
        flex-shrink: 0;
    }

    div:not(:first-of-type) {
        flex: 1;
    }
`;

const StyledDay = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: calc(100vw / 7 - 12px);

    @media screen and (max-width: ${props => props.theme.screenSize.mobile}px) {
        min-width: 70vw;
    }
`;
const DateNumber = styled.div<{ isToday: boolean }>`
    ${props =>
        props.isToday && {
            borderRadius: '50%',
            width: '30px !important',
            height: 30,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: '#272c35',
            backgroundColor: 'red',
        }}
`;
