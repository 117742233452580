import axios from 'axios';

export const meetApiAddress = (() => {
    if (window.location.hostname === 'localhost') {
        return 'http://localhost:4000';
    }
    if (window.location.hostname.endsWith('.test.iterate.no')) {
        return 'https://meet-the-backend.test.iterate.no';
    }
    return 'https://meet-the-backend.app.iterate.no';
})();

export const meetApi = axios.create({
    withCredentials: true,
    baseURL: meetApiAddress,
});
