/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { endOfWeek, getWeek, startOfWeek } from 'date-fns';
import { FC, useEffect, useState } from 'react';
import { formatLocale } from '../../../utils/date';
import { Button } from './Button';

type WeekSelectorType = {
    amountOfWeeksVisible: number;
    setWeekIndex: (weekIndex: number) => void;
};

export const WeekSelector: FC<WeekSelectorType> = ({ amountOfWeeksVisible, setWeekIndex }) => {
    const currentWeekIndex = getWeek(new Date());
    const [selectedWeekIndex, setSelectedWeekIndex] = useState(currentWeekIndex);
    const [firstVisibleWeekIndex, setFirstVisibleWeekIndex] = useState(currentWeekIndex);

    useEffect(() => {
        setWeekIndex(selectedWeekIndex);
        if (selectedWeekIndex > firstVisibleWeekIndex + amountOfWeeksVisible) {
            setFirstVisibleWeekIndex(selectedWeekIndex);
        }
        if (
            firstVisibleWeekIndex !== currentWeekIndex &&
            currentWeekIndex + amountOfWeeksVisible === selectedWeekIndex
        ) {
            setFirstVisibleWeekIndex(currentWeekIndex);
        }
    }, [amountOfWeeksVisible, currentWeekIndex, firstVisibleWeekIndex, selectedWeekIndex, setWeekIndex]);

    return (
        <>
            <div
                css={css`
                    display: flex;
                    justify-content: flex-start;
                    width: 100%;

                    button {
                        &:not(:last-of-type) {
                            margin-right: 8px;
                        }
                    }

                    @media screen and (max-width: 800px) {
                        display: none;
                    }
                `}
            >
                {selectedWeekIndex > currentWeekIndex && (
                    <Button
                        css={css`
                            @media screen and (max-width: 800px) {
                                margin-right: 20px;
                            }
                        `}
                        onClick={() => {
                            setSelectedWeekIndex(selectedWeekIndex - 1);
                        }}
                    >
                        &lsaquo;
                    </Button>
                )}
                {Array.from(Array(amountOfWeeksVisible + 1).keys()).map(week => {
                    const weekIndex = week + firstVisibleWeekIndex;
                    return (
                        <Button
                            key={week}
                            active={weekIndex === selectedWeekIndex}
                            onClick={() => {
                                setSelectedWeekIndex(weekIndex);
                            }}
                            css={css`
                                white-space: nowrap;
                                width: fit-content;
                                @media screen and (max-width: 800px) {
                                    margin-right: 20px;
                                }
                            `}
                        >
                            {navigateWeekText(weekIndex)}
                        </Button>
                    );
                })}
                <Button
                    css={css`
                        grid-area: weekNext;
                    `}
                    onClick={() => {
                        setSelectedWeekIndex(selectedWeekIndex + 1);
                    }}
                >
                    &rsaquo;
                </Button>
            </div>
            <Select
                css={css`
                    display: none;
                    @media screen and (max-width: 800px) {
                        display: block;
                    }
                `}
                onChange={({ target }) => setSelectedWeekIndex(parseInt(target.value))}
            >
                {Array.from(Array(amountOfWeeksVisible + 1).keys()).map(week => {
                    const weekIndex = week + currentWeekIndex;
                    return (
                        <option value={weekIndex} key={week}>
                            {navigateWeekText(weekIndex)}
                        </option>
                    );
                })}
            </Select>
        </>
    );
};

function navigateWeekText(weekIndex: number): string {
    const currentWeekIndex = getWeek(new Date());

    if (weekIndex === currentWeekIndex) {
        const startDayOfWeek = startOfWeek(new Date(), { weekStartsOn: 1 });

        return `Denne uken ${formatLocale(startDayOfWeek, 'dd.MM')} - ${formatLocale(
            endOfWeek(startDayOfWeek, { weekStartsOn: 1 }),
            'dd.MM'
        )}`;
    }
    if (currentWeekIndex + 1 === weekIndex) {
        return 'Neste uke';
    }
    return `Om ${weekIndex - currentWeekIndex} uker`;
}

const Select = styled.select`
    width: 100%;
    padding-left: 8px;
    height: 52px;
    border: 2px solid ${({ theme }) => theme.colors.border.primary};
    color: ${({ theme }) => theme.colors.text.primary};
    font-size: 16px;
    background-color: ${({ theme }) => theme.colors.background.primary};
    border-radius: 4px;
    outline: none;
`;
