import styled from '@emotion/styled';
import { add, differenceInMinutes, differenceInSeconds, isSameWeek, startOfDay } from 'date-fns';
import { FC, useEffect, useRef, useState } from 'react';
import { formatLocale } from '../../../utils/date';

export const Hours: FC<{ date: Date }> = ({ date }) => {
    const isThisWeek = isSameWeek(new Date(), date);

    return (
        <div>
            <StyledHours>
                {isThisWeek && <TimeNow />}
                <div style={{ height: 60 }} />
                {new Array(23).fill('').map((_, i) => (
                    <Tick key={i} hour={i + 1} isThisWeek={isThisWeek} id={`hour-${i + 1}`} />
                ))}
            </StyledHours>
        </div>
    );
};

const TimeNow: FC = () => {
    const [date, setDate] = useState<Date>(new Date());
    const secondsSinceMidnight = differenceInSeconds(date, startOfDay(date));

    useInterval(() => setDate(new Date()), 60000);

    return (
        <span
            style={{
                position: 'absolute',
                top: secondsSinceMidnight / 60.0,
                left: 50,
                width: 'calc(100vw)',
                height: 1,
                backgroundColor: 'red',
            }}
        >
            <span
                style={{
                    position: 'absolute',
                    top: -8,
                    left: -50,
                    color: 'red',
                }}
            >
                {formatLocale(date, 'HH:mm')}
            </span>
        </span>
    );
};

function useInterval(callback: () => void, delay: number | null) {
    const savedCallback = useRef(() => {});

    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
        function tick() {
            savedCallback.current();
        }
        if (delay !== null) {
            let id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
}

const Tick: FC<{ hour: number; isThisWeek: boolean; id: string }> = ({ hour, isThisWeek, id }) => {
    const time = `${hour < 9 ? '0' : ''}${hour}:00`;
    const hideTime =
        isThisWeek && Math.abs(differenceInMinutes(new Date(), add(startOfDay(new Date()), { hours: hour }))) < 15;

    return (
        <TickStyle id={id}>
            <div className="border" />
            <div className="time">{hideTime ? '' : time}</div>
        </TickStyle>
    );
};

const TickStyle = styled.div`
    height: 60px;
    position: relative;

    .time {
        position: relative;
        top: -10px;
    }

    .border {
        position: absolute;
        top: 0;
        left: 47px;
        width: calc(calc(100vw / 7 - 12px) * 7 + 12px);
        height: 1px;
        background-color: ${props => props.theme.colors.border.primary};

        @media screen and (max-width: ${props => props.theme.screenSize.mobile}px) {
            width: calc(70vw * 7 + 12px);
        }
    }
`;

const StyledHours = styled.div`
    position: relative;
    border-color: ${props => props.theme.colors.border.primary};
    border-style: solid;
    border-width: 0 1px 0 0;
    width: 60px;
`;
